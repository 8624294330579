<div class="azd-header-user-detail" *ngIf="userData">
    <div nxAvatar aria-label="Avatar" size="small" class="nx-margin-right-xs">
        <nx-icon name="user-o"></nx-icon>
    </div>
    <div class="azd-header-user-detail__userDetail">
        <span>
            {{ userData.name + ' ' + userData.surname }}
            <br />
            {{ userProfile ? (userProfile | translate) : '' }}
        </span>
    </div>
    <nx-link class="--mobileOnly" *ngIf="userData?.can_logout">
        <a [routerLink]="null" (click)="onLogoutClick()">Logout</a>
    </nx-link>
    <div
        class="azd-header-user-detail__menu nx-margin-left-xl --desktopOnly"
        *ngIf="
            (userData?.can_switch_account && !isDevEnv) ||
            userData?.can_logout ||
            (userData.role_id &&
                !constantsFE.USER_ROLES_AGENT_GROUP.concat(constantsFE.USER_ROLES_ADMIN_GROUP).includes(roleById[userData.role_id].tag)) ||
            !userData.role_id
        "
    >
        <nx-icon
            [nxPopoverTriggerFor]="azdMenu"
            nxPopoverDirection="bottom"
            nxPopoverCloseable="false"
            nxPopoverTrigger="click"
            name="chevron-down"
        ></nx-icon>
        <nx-popover #azdMenu>
            <div class="azd-popover__wrapper">
                <nx-link *ngIf="userData?.can_switch_account && !isDevEnv">
                    <a [routerLink]="null" (click)="switchLogin(true)" *permissionsOnly="constantsFE.USER_ROLES_ADMIN_GROUP"
                        >Accedi come Dipendente</a
                    >
                    <a [routerLink]="null" (click)="switchLogin()" *ngIf="userData?.role_id === undefined">Accedi come Ufficio Eventi</a>
                </nx-link>
                <nx-link *permissionsExcept="constantsFE.USER_ROLES_AGENT_GROUP.concat(constantsFE.USER_ROLES_ADMIN_GROUP)">
                    <a routerLink="/my-account">Il mio account</a>
                </nx-link>
                <nx-link *ngIf="userData?.can_logout">
                    <a [routerLink]="null" (click)="onLogoutClick()">Logout</a>
                </nx-link>
            </div>
        </nx-popover>
    </div>
</div>
