import { isPlatformBrowser, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, Inject, Injectable, InjectionToken, Injector, PLATFORM_ID } from '@angular/core';

import { LoggerService } from '@app/core/services/base/logger.service';

import { ErrorHandlerModel } from '@app/shared/models/error-handler.model';

import { environment } from '@env/environment';

import { CustomNGXLoggerService, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class HandleErrorService implements ErrorHandler {
    private HLogger!: NGXLogger;
    private lastError!: ErrorHandlerModel;

    constructor(
        private injector: Injector,
        private customLogger: CustomNGXLoggerService,
        private loggerService: LoggerService,
        @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.HLogger = this.customLogger.create(
                {
                    serverLoggingUrl: 'log_fe_exception',
                    level: NgxLoggerLevel[environment.debug as keyof typeof NgxLoggerLevel],
                    serverLogLevel: NgxLoggerLevel[environment.debugServer as keyof typeof NgxLoggerLevel],
                },
                this.loggerService as any
            );
        }
    }

    handleError(error: ErrorHandlerModel): void {
        if (isPlatformBrowser(this.platformId)) {
            const errorInfo: ErrorHandlerModel = {
                name: '',
                message: error.message ? error.message : error.toString(),
                url: this.injector.get(LocationStrategy) instanceof PathLocationStrategy ? this.injector.get(LocationStrategy).path() : '',
            };

            if (!this.lastError || (this.lastError.message !== errorInfo.message && this.lastError.url !== errorInfo.url)) {
                this.lastError = errorInfo;
                StackTrace.fromError(error).then((stackFrames: StackTrace.StackFrame[]) => {
                    const stackString = stackFrames
                        .splice(0, 20)
                        .map((sf: StackTrace.StackFrame) => {
                            return sf.toString();
                        })
                        .join('\n');

                    const infoForServer = {
                        url: errorInfo.url,
                        stack: stackString,
                    };
                    this.HLogger.error(errorInfo.message, infoForServer);
                });
            }
        } else {
            throw error;
        }
    }
}
