import { Component } from '@angular/core';

@Component({
    selector: 'azd-layout-page',
    template: `
        <azd-header></azd-header>
        <router-outlet></router-outlet>
        <azd-footer></azd-footer>
        <azd-error></azd-error>
    `,
    styles: []
})
export class LayoutPageComponent {
}
