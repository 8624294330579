import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HandleErrorService } from '@app/core/services/base/handle-error.service';

import { AuthGuard } from '@app/core/guards/auth.guards';
import { DevEnvironmentGuard } from '@app/core/guards/dev-environment.guard';

import { APIInterceptor } from '@app/core/interceptors/api.interceptor';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        AuthGuard,
        DevEnvironmentGuard,
        {
            provide: ErrorHandler,
            useClass: HandleErrorService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {
}
