import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';

import { UtilsService } from '../services/base/utils.service';

@Injectable()
export class AuthGuard {
    constructor(
        private authService: AuthService,
        private localStorageManagementService: LocalStorageManagementService,
        private utilsService: UtilsService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.utilsService.checkIfDevelopmentEnvironment() || !this.utilsService.isPrivateHostname()) {
            if (this.authService.isTokenExpired()) {
                if (this.localStorageManagementService.getRefreshToken()) {
                    return this.authService.refreshToken().pipe(
                        switchMap(() => of(true)),
                        catchError(() => {
                            return of(true);
                        })
                    );
                } else {
                    return of(true);
                }
            } else {
                return true;
            }
        } else {
            if (this.authService.isTokenExpired()) {
                const loginType = this.authService.getLoginTypeFromExternalUrl();

                if (loginType) {
                    switch (loginType) {
                        case 'employee':
                            return this.authService.employeeLogin().pipe(
                                switchMap(() => this.authService.getUser()),
                                switchMap(() => of(true)),
                                catchError(() => {
                                    return of(true);
                                })
                            );
                        case 'agent':
                        case 'consultant':
                        case 'admin':
                            return this.authService.crpLogin().pipe(
                                switchMap(() => this.authService.getUser()),
                                switchMap(() => of(true)),
                                catchError(() => {
                                    return of(true);
                                })
                            );
                        default:
                            return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }
}
