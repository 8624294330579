import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NxDialogService, NxModalRef, NX_MODAL_DATA } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { UserService } from '@app/core/services/user.service';

import { Constants } from '@app/core/constants/constants';
import { DataModalModel } from '@app/shared/models/data-modal.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { CustomValidators } from '@app/shared/validators/custom-validators';

import { environment } from '@env/environment';

@Component({
    selector: 'azd-profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit, OnDestroy {
    public constantsFE = Constants;
    public currentStep = 1;
    public modalForm!: UntypedFormGroup;
    public loading = false;
    public error = false;
    public isFromLink = false;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public censorEmail!: string;
    public nextStep = Constants.PROFILE_MODAL_STEP_LOGIN;
    public env = environment;
    private recoveryFiscalCodeSubscription!: Subscription;

    constructor(
        @Inject(NX_MODAL_DATA) public data: DataModalModel,
        private errorService: ErrorService,
        private dialogService: NxDialogService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private userService: UserService,
        private utilsService: UtilsService,
        private dialog: NxModalRef<ProfileModalComponent>
    ) {
        if (data && Object.keys(data).length !== 0) {
            if (data.step !== undefined) {
                this.currentStep = data.step;
                this.nextStep = data.nextStep !== undefined ? data.nextStep : this.nextStep;
                this.isFromLink = true;
            }
        }

        if (this.isFromLink) {
            this.dialog.afterClosed().subscribe(() => {
                this.utilsService.reloadPageWithoutParameters();
            });
        }
    }

    ngOnInit(): void {
        console.log('env', this.env);
        this.createForm();
        this.listenForRecoveryFiscalCodeChange();
    }

    createForm(): void {
        this.modalForm = this.formBuilder.group({
            recovery_fiscal_code: [null, CustomValidators.validFiscalCode],
            login: this.formBuilder.group({
                email: [null, CustomValidators.validEmail],
                password: [null, Validators.required],
                remember_me: [],
            }),
            profile: this.formBuilder.group({
                is_customer_step: this.formBuilder.group({
                    is_allianz_customer: [],
                    is_allianz_bank_customer: [],
                }),
                personal_data_step: this.formBuilder.group(
                    {
                        name: [null, Validators.required],
                        surname: [null, Validators.required],
                        fiscal_code: [null, CustomValidators.validFiscalCode],
                        email: [null, CustomValidators.validEmail],
                        confirm_email: [null, CustomValidators.validEmail],
                        password: [null, CustomValidators.validPassword],
                        confirm_password: [null, Validators.required],
                    },
                    {
                        validators: [CustomValidators.matchEmail, CustomValidators.matchPassword],
                    }
                ),
            }),
        });
    }

    listenForRecoveryFiscalCodeChange(): void {
        if (this.modalForm.get('recovery_fiscal_code')) {
            this.recoveryFiscalCodeSubscription = this.modalForm
                .get('recovery_fiscal_code')!
                .valueChanges.subscribe(() => (this.error = false));
        }
    }

    onSetCurrentStepClick(step: number | undefined = undefined): void {
        if (step === undefined) {
            this.closeModal();
            return;
        }
        this.currentStep = step;
    }

    closeModal(): void {
        this.dialogService.closeAll();
    }

    onLoginClick(): void {
        if (this.modalForm.get('login')?.invalid) {
            return;
        }

        this.authService
            .login(
                this.modalForm.get('login.email')?.value,
                this.modalForm.get('login.password')?.value,
                this.modalForm.get('login.remember_me')?.value
            )
            .pipe(switchMap(() => this.authService.getUser()))
            .subscribe(
                () => {
                    this.closeModal();
                    window.location.href = window.location.href.split('?')[0];
                },
                (error: HttpErrorResponse) => {
                    this.errorService.openModalError(error, 'Errore nella login');
                }
            );
    }

    onRecoveryPasswordClick(): void {
        if (this.modalForm.get('recovery_fiscal_code')?.invalid) {
            return;
        }
        this.loading = true;
        this.error = false;
        const formData = {
            fiscal_code: this.modalForm.get('recovery_fiscal_code')?.value?.toUpperCase(),
        };

        this.authService.resetUserPassword(formData).subscribe(
            (response: any) => {
                this.censorEmail = response.email;
                this.loading = false;
                this.currentStep = Constants.PROFILE_MODAL_STEP_RECOVERY_SUCCESS;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.error = true;
                this.errorService.openModalError(error, 'Errore nel recupero della password');
            }
        );
    }

    onCreateProfileClick(): void {
        if (this.modalForm.get('profile')?.invalid) {
            return;
        }
        this.loading = true;
        const formData = {
            name: this.modalForm.get('profile.personal_data_step.name')?.value,
            surname: this.modalForm.get('profile.personal_data_step.surname')?.value,
            email: this.modalForm.get('profile.personal_data_step.email')?.value,
            fiscal_code: this.modalForm.get('profile.personal_data_step.fiscal_code')?.value?.toUpperCase(),
            password: this.modalForm.get('profile.personal_data_step.password')?.value,
        };

        this.userService.registerUser(formData).subscribe(
            (response) => {
                this.censorEmail = response.email;
                this.loading = false;
                this.currentStep = Constants.PROFILE_MODAL_STEP_PENDING;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.errorService.openModalError(error, 'Errore nella registrazione di un utente');
            }
        );
    }

    onOpenFileClick(): void {
        this.utilsService.openPrivacyFile();
    }

    goToLink(link: string): void {
        window.open(link, '_self');
    }

    ngOnDestroy(): void {
        this.recoveryFiscalCodeSubscription.unsubscribe();
    }
}
