import { Component, OnInit } from '@angular/core';
import { Constants } from '@app/core/constants/constants';
import { ErrorModalModel } from '@app/shared/models/error-modal.model';
import { ErrorService } from '@app/core/services/base/error.service';

@Component({
    selector: 'azd-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    public error!: ErrorModalModel | null;
    public isOpen = false;
    public constantsFE = Constants;

    constructor(
        private errorService: ErrorService
    ) {
        this.errorService.openModalError$.subscribe(
            (params: ErrorModalModel) => {
                this.error = params;
                if (typeof this.error.error !== 'object') {
                    this.isOpen = true;
                    setTimeout(() => {
                        this.isOpen = false;
                        setTimeout(() => {
                            this.error = null;
                        }, 2000);
                    }, 3000);
                } else {
                    console.log('ERROR: ' + this.error.message);
                }
            }
        );
    }

    ngOnInit(): void {
    }

}
