import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { RoleBasedPermissionsService } from '@app/core/services/base/role-based-permissions.service';

@Directive({
    selector: '[permissionsOnly]'
})
export class PermissionsOnlyDirective implements OnInit, OnDestroy {

    private permissionSubscription$!: Subscription;

    @Input('permissionsOnly') permissionsOnly!: string | string[];

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private roleBasedPermissions: RoleBasedPermissionsService
    ) {
    }

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {
        this.permissionSubscription$ = this.roleBasedPermissions.checkAuthorizationOnly(this.permissionsOnly).subscribe(
            (authorized: boolean) => {
                if (authorized) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            },
            () => {
                this.viewContainer.clear();
            }
        );
    }

    ngOnDestroy(): void {
        this.permissionSubscription$.unsubscribe();
    }
}
