import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BulletinMessage } from '@app/shared/models/bulletin-message.model';

@Injectable({
    providedIn: 'root',
})
export class BulletinMessagesService {
    constructor(private http: HttpClient) {}

    //
    // ─── HTTP REQUESTS ──────────────────────────────────────────────────────────────
    //

    //
    // ─── GET ────────────────────────────────────────────────────────────────────
    //

    getUserMessages(): Observable<BulletinMessage[]> {
        return this.http.get<BulletinMessage[]>(`user_bulletin_messages`);
    }

    //
    // ─── POST ────────────────────────────────────────────────────────────────────
    //

    //
    // ─── PATCH ────────────────────────────────────────────────────────────────────
    //

    hideUserMessages(): Observable<any> {
        return this.http.patch<any>(`user_bulletin_messages`, {});
    }

    //
    // ─── PUT ────────────────────────────────────────────────────────────────────
    //

    //
    // ─── DELETE ────────────────────────────────────────────────────────────────────
    //
}
