import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'azd-empty-results',
    templateUrl: './empty-results.component.html',
    styleUrls: ['./empty-results.component.scss']
})
export class EmptyResultsComponent implements OnInit {
    @Input() message!: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
