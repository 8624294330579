import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConstantsService {
    private constants: any;

    private retrieveConstantsInProgress = false;
    private retrieveConstantsSource = new Subject();
    // tslint:disable-next-line:member-ordering
    retrieveConstants$ = this.retrieveConstantsSource.asObservable();

    constructor(
        private http: HttpClient
    ) {
    }

    getConstants(): Observable<any> {
        if (this.constants) {
            return of(this.constants);
        } else {
            return this.handleMultipleAPIRequest();
        }
    }

    retrieveConstants(): Observable<any> {

        return this.http.get<any>('get_constants').pipe(
            tap((response: any) => {
                this.constants = response;
            })
        );
    }

    //
    // ─── MANAGE MULTIPLE API REQUEST ────────────────────────────────────────────────────────────────────
    //

    handleMultipleAPIRequest(): any {
        if (this.retrieveConstantsInProgress) {
            return new Observable((observer: any) => {
                this.retrieveConstants$.pipe(
                    finalize(() => this.retrieveConstantsInProgress = false)
                ).subscribe((constants: any) => {
                    observer.next(constants);
                    observer.complete();
                });
            });
        } else {
            this.retrieveConstantsInProgress = true;
            return this.retrieveConstants().pipe(
                tap((constants: any) => this.retrieveConstantsSource.next(constants)),
                finalize(() => this.retrieveConstantsInProgress = false)
            );
        }
    }
}
