<ng-template #messagesModalTemplate>
    <div class="azw-userBulletinMessagesModal">
        <h3 nxHeadline="subsection-medium" class="nx-modal-margin-bottom-s">
            <img src="./assets/images/logoAZ-world-of-event-L.png" alt="Allianz Global Brand Logo" width="180px" />
        </h3>
        <div class="azw-userBulletinMessagesModal__message" *ngFor="let message of messages">
            <h3>{{ message.title }}</h3>
            <p [innerHTML]="message.message"></p>
        </div>
        <nx-checkbox class="nx-margin-bottom-s" (checkboxChange)="hideMessages = !hideMessages">
            Non mostrare più questi messaggi
        </nx-checkbox>
        <button nxModalClose class="nx-margin-bottom-0" nxButton="primary" type="button">Chiudi</button>
    </div>
</ng-template>
