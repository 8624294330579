import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { EditUserModel, RegisterUserModel, UserModel, UserPasswordModel } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private refreshUserData = new Subject<UserModel>();
    public refreshUserData$ = this.refreshUserData.asObservable();

    private refreshUserDataPassword = new Subject<void>();
    public refreshUserDataPassword$ = this.refreshUserDataPassword.asObservable();

    private refreshToggleLoginModal = new Subject<number>();
    public refreshToggleLoginModal$ = this.refreshToggleLoginModal.asObservable();

    constructor(
        private http: HttpClient
    ) {
    }

    //
    // ─── HTTP REQUEST - GET ────────────────────────────────────────────────────────
    //

    getUser(): Observable<UserModel> {
        return this.http.get<UserModel>(`logged_user?is_verbose=true`);
    }

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    registerUser(formData: RegisterUserModel): Observable<UserModel> {
        return this.http.post<UserModel>(`register_user`, formData);
    }

    confirmEmail(token: string): Observable<[]> {
        const formData = {
            encrypted_user_token: token
        };
        return this.http.post<[]>(`confirm_user_email`, formData);
    }

    confirmInvite(token: string): Observable<[]> {
        const formData = {
            encrypted_user_token: token
        };
        return this.http.post<[]>(`confirm_invite`, formData);
    }

    updateUserPassword(formData: UserPasswordModel): Observable<UserModel> {
        return this.http.post<UserModel>(`change_logged_user_password`, formData);
    }

    resendMailConfirmation(): Observable<unknown> {
        return this.http.post<unknown>(`resend_mail_confirmation`, {});
    }

    //
    // ─── HTTP REQUEST - PATCH ────────────────────────────────────────────────────────
    //

    updateUser(formData: EditUserModel, userId: number): Observable<UserModel> {
        return this.http.patch<UserModel>(`users/${userId}`, formData);
    }

    //
    // ─── RXJS ─────────────────────────────────────────────────────────────────────────
    //

    updateUserData(userData: UserModel): void {
        this.refreshUserData.next(userData);
    }

    updateUserDataPassword(): void {
        this.refreshUserDataPassword.next();
    }

    toggleLoginModal(step: number): void {
        this.refreshToggleLoginModal.next(step);
    }

}
