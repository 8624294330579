import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'azd-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public currentYear = new Date().getFullYear();

    constructor() {
    }

    ngOnInit(): void {
    }

}
