import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { Constants } from '@app/core/constants/constants';
import { AuthResponseModel } from '@app/shared/models/auth-response.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { TokenResponseModel } from '@app/shared/models/token-response.model';

import { LocalStorageManagementService } from './local-storage-management.service';

import * as dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userData!: LoggedUserModel | null;
    private retrieveUserDataSource = new Subject<LoggedUserModel>();
    private retrieveUserData$ = this.retrieveUserDataSource.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    retrieveUserDataInProgress = false;

    private refreshLoginError = new Subject<HttpErrorResponse>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public refreshLoginError$ = this.refreshLoginError.asObservable();

    constructor(private localStorageManagementService: LocalStorageManagementService, private http: HttpClient, private router: Router) {}

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    login(email: string, password: string, rememberMe: boolean): Observable<AuthResponseModel> {
        const params = {
            email: email,
            password: password,
            remember_me: rememberMe,
        };
        return this.http.post<AuthResponseModel>('login', params).pipe(
            tap((user: AuthResponseModel) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
            })
        );
    }

    loginAsEmployee(email: string, password: string, rememberMe: boolean): Observable<AuthResponseModel> {
        const params = {
            email: email,
            password: password,
            remember_me: rememberMe,
        };
        return this.http.post<AuthResponseModel>('azw_employee_login', params).pipe(
            tap((user: AuthResponseModel) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
                this.localStorageManagementService.setLoginType('employee');
            })
        );
    }

    azwLogin(email: string, password: string, rememberMe: boolean): Observable<AuthResponseModel> {
        const params = {
            email: email,
            password: password,
            remember_me: rememberMe,
        };
        return this.http.post<AuthResponseModel>('azw_login', params).pipe(
            tap((user: AuthResponseModel) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
                this.localStorageManagementService.setLoginType('agent');
            })
        );
    }

    crpLogin(): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>('azw_login', {}).pipe(
            tap((user: AuthResponseModel) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
                this.localStorageManagementService.setLoginType('agent');
            })
        );
    }

    employeeLogin(): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>('azw_employee_login', {}).pipe(
            tap((user: AuthResponseModel) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
                this.localStorageManagementService.setLoginType('employee');
            })
        );
    }

    logout(): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>('logout', {}).pipe(
            finalize(() => {
                this.afterLogout();
            })
        );
    }

    azwLogout(): Observable<AuthResponseModel> {
        return this.http.post<AuthResponseModel>('azw_logout', {}).pipe(
            finalize(() => {
                this.afterLogout();
            })
        );
    }

    resetUserPassword(formData: any): Observable<any> {
        return this.http.post<any>('reset_user_password', formData);
    }

    validateUserToken(formData: any): Observable<TokenResponseModel> {
        return this.http.post<TokenResponseModel>('validate_user_token', formData);
    }

    changeUserPassword(formData: any, token: string): Observable<any> {
        let headers = new HttpHeaders();
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }
        return this.http.post<any>('change_user_password', formData, { headers });
    }

    getLoginTypeFromExternalUrl(): string {
        let loginType = 'agent';

        if (this.localStorageManagementService.getLoginType()) {
            loginType = this.localStorageManagementService.getLoginType();
        } else {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams: any, prop: any) => searchParams.get(prop),
            });

            if (params['logintype']) {
                loginType = params['logintype'];
            }
        }

        return loginType;
    }

    //
    // ─── TOKEN METHODS ──────────────────────────────────────────────────────────────
    //

    getTokenExpirationDate(token: string): dayjs.Dayjs | null {
        try {
            const decoded: any = jwt_decode(token);

            if (decoded.exp === undefined) {
                return null;
            }

            return dayjs.unix(decoded.exp);
        } catch (error) {
            return null;
        }
    }

    isTokenExpired(token?: string): boolean {
        const authToken = token || this.localStorageManagementService.getToken();

        if (!authToken) {
            return true;
        }

        const date = this.getTokenExpirationDate(authToken);

        if (!date) {
            return true;
        }

        return date.isBefore(dayjs());
    }

    refreshToken(): Observable<AuthResponseModel> {
        const params = {
            refresh_token: this.localStorageManagementService.getRefreshToken(),
        };

        return this.http.post<AuthResponseModel>('token/refresh', params).pipe(
            tap(
                (response: AuthResponseModel) => {
                    this.localStorageManagementService.setToken(response.token);
                    this.localStorageManagementService.setRefreshToken(response.refresh_token);
                    return true;
                },
                () => {
                    this.logout().subscribe();
                    return false;
                }
            )
        );
    }

    //
    // ─── USER - METHODS ─────────────────────────────────────────────────────────────
    //

    getUser(): Observable<LoggedUserModel> {
        if (this.userData) {
            return of(this.userData);
        } else {
            return this.handleMultipleAPIRequest();
        }
    }

    getLoggedUser(): Observable<LoggedUserModel> {
        return this.http.get<LoggedUserModel>('logged_user', {}).pipe(
            tap((user: LoggedUserModel) => {
                this.userData = user;
                this.localStorageManagementService.setUser(user);
            })
        );
    }

    updateUser(user: LoggedUserModel): void {
        this.userData = user;
    }

    clearUserData(): void {
        this.userData = null;
    }

    get userLocalData(): LoggedUserModel {
        return JSON.parse(localStorage.getItem(Constants.USER) as string);
    }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    goToLogin(): void {
        this.router.navigate(['/']);
    }

    handleMultipleAPIRequest(): Observable<LoggedUserModel> {
        if (this.retrieveUserDataInProgress) {
            return new Observable((observer: any) => {
                this.retrieveUserData$
                    .pipe(finalize(() => (this.retrieveUserDataInProgress = false)))
                    .subscribe((userData: LoggedUserModel) => {
                        observer.next(userData);
                        observer.complete();
                    });
            });
        } else {
            this.retrieveUserDataInProgress = true;
            return this.getLoggedUser().pipe(
                tap((userData: LoggedUserModel) => this.retrieveUserDataSource.next(userData)),
                finalize(() => (this.retrieveUserDataInProgress = false))
            );
        }
    }

    afterLogout(): void {
        this.localStorageManagementService.deleteTokens();
        this.localStorageManagementService.deleteUser();
        this.localStorageManagementService.deleteLoginType();
        this.clearUserData();
        this.goToLogin();
    }

    //
    // ─── RXJS ─────────────────────────────────────────────────────────────────────────
    //

    updateLoginError(error: HttpErrorResponse): void {
        this.refreshLoginError.next(error);
    }
}
