import { Directive, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { EventService } from '@app/core/services/event.service';

import { EventModel } from '../models/event.model';
import { EventFilterModel } from '../models/filter.model';

import * as dayjs from 'dayjs';

@Directive({
    selector: '[azdPaginatedEvents]',
})
export class PaginatedEventsDirective implements OnDestroy {
    public filters!: EventFilterModel;
    public searchStringSubscription!: Subscription;
    public totalElements = 0;
    public loading = true;
    public currentScopeTag!: string;
    public filter: any;

    // EVENTS
    public events: EventModel[] = [];
    public calendarEvents: EventModel[] = [];
    public originalEvents = [];

    public page = 1;
    public numberOfEvents = 5;

    private searchText: UntypedFormControl;

    constructor(protected eventService: EventService) {
        this.searchText = new UntypedFormControl();
        this.subscribeOnSearchStringChanges();

        this.eventService.refreshListByFilterFromApi$.subscribe((action: string) => {
            this.filters.page = this.page;
            if (action === 'resetFilters') {
                this.resetAllFilters();
            }
            if (action === 'showAll') {
                this.filters.start_date = null;
            }
            if (action === 'singleEvent') {
                this.setFiltersForSingleEvent();
            }
            this.getEvents(true);
        });
    }

    subscribeOnSearchStringChanges(): void {
        this.searchStringSubscription = this.searchText.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((value: string) => {
                if (value || value === '') {
                    this.filters.page = 1;
                    this.getEvents(true);
                }
            });
    }

    setFiltersForSingleEvent(): void {
        this.filters = {
            ...this.filters,
            event_name: null,
            event_type: null,
            event_category: null,
            event_target: null,
            event_status: null,
            is_archived: null,
            limit: null,
            page: null,
        };
        this.searchText.setValue(null);
    }

    resetAllFilters(): void {
        this.filters = {
            event_name: null,
            start_date: dayjs(),
            event_type: null,
            event_category: null,
            event_target: null,
            event_status: null,
            is_archived: null,
            event: null,
            limit: this.numberOfEvents,
            page: this.page,
        };
        this.searchText.setValue(null);
    }

    getEvents(resetEvents: boolean): void {}

    handleSearchFilter(): void {
        this.searchText.setValue(this.filters.event_name);
    }

    updateListFromApi(): void {
        this.eventService.updateListByFilterFromApi();
    }

    prevPage(resetEvents = false): void {
        if (this.filters.page) this.filters.page--;
        this.getEvents(resetEvents);
    }

    nextPage(resetEvents = false): void {
        if (this.filters.page) this.filters.page++;
        this.getEvents(resetEvents);
    }

    goToPage(n: number): void {
        if (this.filters.page) this.filters.page = n;
        this.getEvents(false);
    }

    showMore(): void {
        if (this.filters.page) this.filters.page++;

        this.getEvents(false);
    }

    resetEvents(): void {
        this.events = [];
        this.originalEvents = [];
        this.calendarEvents = [];
    }

    ngOnDestroy(): void {
        if (this.searchStringSubscription) {
            this.searchStringSubscription.unsubscribe();
        }
    }
}
