import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

import codiceFiscale from 'codice-fiscale-js';

export class CustomValidators {
    static validPassword(c: AbstractControl): ValidationErrors | null {
        const password = c.value;
        const reg = /^(?=.{10,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!%@#\\$\\^\\*?_\\-\\.~()=]).*$/;

        let isValid = true;
        const message = {
            validPassword: true,
        };

        isValid = reg.test(password);

        return isValid ? null : message;
    }

    static validEmail(c: AbstractControl): ValidationErrors | null {
        const email = c.value;
        const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let isValid = true;
        const message = {
            validEmail: true,
        };

        isValid = reg.test(email);

        return isValid ? null : message;
    }

    static validEmailNotRequired(c: AbstractControl): ValidationErrors | null {
        const email = c.value;
        const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let isValid = true;
        const message = {
            validEmail: true,
        };

        isValid = email ? reg.test(email) : true;

        return isValid ? null : message;
    }

    static validFiscalCode(c: UntypedFormControl): ValidationErrors | null {
        const fiscalCode = c.value;
        let isValid = true;
        const message = {
            validFiscalCode: true,
        };

        isValid = fiscalCode && codiceFiscale.check(fiscalCode.toUpperCase());

        return isValid ? null : message;
    }

    static matchPassword(control: AbstractControl): void {
        const passwordControl = control.get('password') as UntypedFormControl;
        const passwordConfirmationControl = control.get('confirm_password') as UntypedFormControl;

        if (passwordControl.value !== passwordConfirmationControl.value) {
            passwordConfirmationControl.setErrors({ passwordConfirmation: true });
        } else {
            passwordConfirmationControl.setErrors(null);
        }
    }

    static matchEmail(control: AbstractControl): void {
        const emailControl = control.get('email') as UntypedFormControl;
        const emailConfirmationControl = control.get('confirm_email') as UntypedFormControl;

        if (emailControl.value !== emailConfirmationControl.value) {
            emailConfirmationControl.setErrors({ emailConfirmation: true });
        } else {
            emailConfirmationControl.setErrors(null);
        }
    }
}
