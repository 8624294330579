import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AttachmentBoxComponent } from '@app/shared/components/attachment-box/attachment-box.component';
import { BadgeComponent } from '@app/shared/components/badge/badge.component';
import { EmptyResultsComponent } from '@app/shared/components/empty-results/empty-results.component';
import { ErrorComponent } from '@app/shared/components/error/error.component';
import { ExperienceRegistrationModalComponent } from '@app/shared/components/experience-registration-modal/experience-registration-modal.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { HeaderUserDetailComponent } from '@app/shared/components/header/header-user-detail/header-user-detail.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { ProfileModalComponent } from '@app/shared/components/profile-modal/profile-modal.component';
import { PermissionsExceptDirective } from '@app/shared/directives/permissions-except.directive';
import { PermissionsOnlyDirective } from '@app/shared/directives/permissions-only.directive';
import { LayoutBlankPageComponent } from '@app/shared/layouts/layout-blank-page.component';
import { LayoutPageComponent } from '@app/shared/layouts/layout-page.component';
import { NdbxModule } from '@app/shared/ndbx/ndbx.module';
import { BytesToSizePipe } from '@app/shared/pipes/bytes-to-size.pipe';
import { DateParserPipe } from '@app/shared/pipes/date-parser.pipe';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { SortEventTypesPipe } from '@app/shared/pipes/sort-event-types.pipe';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';

import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { ConfirmationModalComponent } from './components/cancel-subscription-modal/confirmation-modal.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { ModalUtilsComponent } from './components/modal-utils/modal-utils.component';
import { OnboardingModalComponent } from './components/onboarding-modal/onboarding-modal.component';
import { ShareEventModalComponent } from './components/share-event-modal/share-event-modal.component';
import { UserBulletinMessagesModalComponent } from './components/user-bulletin-messages-modal/user-bulletin-messages-modal.component';
import { PaginatedEventsDirective } from './directives/paginated-events.directive';

import { NgxUploaderModule } from 'ngx-uploader';
import { SortPipe } from './pipes/sort.pipe';

@NgModule({
    declarations: [
        LayoutPageComponent,
        LayoutBlankPageComponent,
        HeaderComponent,
        FooterComponent,
        HeaderUserDetailComponent,
        BadgeComponent,
        AttachmentBoxComponent,
        ProfileModalComponent,
        ExperienceRegistrationModalComponent,
        EmptyResultsComponent,
        ErrorComponent,
        ShareEventModalComponent,
        LoginModalComponent,
        OnboardingModalComponent,
        ConfirmationModalComponent,
        OnboardingModalComponent,
        FeedbackModalComponent,
        ModalUtilsComponent,
        AdditionalInformationComponent,
        UserBulletinMessagesModalComponent,
        SortEventTypesPipe,
        DateParserPipe,
        SafePipe,
        TruncatePipe,
        BytesToSizePipe,
        SortPipe,
        PermissionsExceptDirective,
        PermissionsOnlyDirective,
        PaginatedEventsDirective,
    ],
    imports: [
        //
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NdbxModule,
        TranslateModule,
        NgxUploaderModule,
    ],
    exports: [
        NdbxModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LayoutPageComponent,
        LayoutBlankPageComponent,
        HeaderComponent,
        FooterComponent,
        HeaderUserDetailComponent,
        BadgeComponent,
        AttachmentBoxComponent,
        ProfileModalComponent,
        ExperienceRegistrationModalComponent,
        EmptyResultsComponent,
        ErrorComponent,
        ShareEventModalComponent,
        SortEventTypesPipe,
        LoginModalComponent,
        OnboardingModalComponent,
        ConfirmationModalComponent,
        OnboardingModalComponent,
        FeedbackModalComponent,
        ModalUtilsComponent,
        AdditionalInformationComponent,
        UserBulletinMessagesModalComponent,
        DateParserPipe,
        SafePipe,
        TruncatePipe,
        BytesToSizePipe,
        SortPipe,
        PermissionsExceptDirective,
        PermissionsOnlyDirective,
        PaginatedEventsDirective,
    ],
})
export class SharedModule {}
