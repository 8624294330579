import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppSettingModel } from '@app/shared/models/app-setting.model';
import { environment } from '@env/environment';
import { UtilsService } from './utils.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppSettingService {
    public appSetting!: AppSettingModel;

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
        @Optional() @Inject('serverUrl') protected serverUrl: string,
        private utilsService: UtilsService
    ) {
    }

    loadAppSetting(): any {
        return this.retrieveSettings()
            .then((config: AppSettingModel | undefined) => {
                if (config) {
                    this.appSetting = config;
                    this.overrideStaticConfiguration(config);
                }
            })
            .catch(() => {
                this.appSetting = environment;
            });
    }

    async retrieveSettings(): Promise<AppSettingModel> {
        const config = this.http.get<AppSettingModel>('assets/settings/settings.json');
        return await lastValueFrom(config);
    }

    getSetting(): AppSettingModel | null {
        return this.appSetting;
    }

    private overrideStaticConfiguration(config: AppSettingModel): void {
        environment.production = config.production;
        environment.env = config.env;
        environment.googleMapsApiKey = config.googleMapsApiKey;
        environment.debug = config.debug;
        environment.debugServer = config.debugServer;
        environment.agentPortalUrl = config.agentPortalUrl;
        environment.consultantPortalUrl = config.consultantPortalUrl;
        environment.employeePortalUrl = config.employeePortalUrl;
        if (this.utilsService.isPrivateHostname()) {
            if (this.utilsService.isConsultantHost()) {
                environment.apiUrl = config.consultantUrl;
            } else {
                environment.apiUrl = config.privateUrl;
            }
        } else {
            environment.apiUrl = config.apiUrl;
        }
    }
}
