// tslint:disable: member-ordering
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { EventModel } from '@app/shared/models/event.model';
import { AttachmentModel } from '@app/shared/models/attachment.model';
import { CustomerSatisfactionDataModel } from '@app/shared/models/customer-satisfaction.model';
import { AgencyModel } from '@app/shared/models/agency.model';
import { UserAnagraphicModel } from '@app/shared/models/user.model';
import { FilteredConstantsModel } from '@app/shared/models/constant.model';
import { EventFilterModel } from '@app/shared/models/filter.model';
import { UtilsService } from './base/utils.service';
import { Constants } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private refreshScheduledEvent = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public refreshScheduledEvent$ = this.refreshScheduledEvent.asObservable();

    private refreshEvent = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public refreshEvent$ = this.refreshEvent.asObservable();

    private refreshListByFilterFromApi = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public refreshListByFilterFromApi$ = this.refreshListByFilterFromApi.asObservable();

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
    ) {}

    //
    // ─── HTTP REQUEST - GET ────────────────────────────────────────────────────────
    //

    getEvents(filters: EventFilterModel, path: string = ''): Observable<any> {
        const url = `${path}events`;
        const params: any = {
            status_id: filters.event_status,
            name: filters?.event_name,
            type_id: filters.event_type,
            category_id: filters.event_category,
            target_id: filters.event_target,
            event_id: filters?.event,
            is_archived: filters?.is_archived,
            scope_id: filters.scope_id,
            page: filters.page,
            limit: filters.limit,
        };

        if (filters.start_date) {
            params['end_date'] = filters.start_date.format('YYYY-MM-DD');
        }

        if (params.event_id) {
            params['start_date'] = null;
        }

        if (filters.calendar_end_date && filters.calendar_start_date) {
            params['calendar_start_date'] = filters.calendar_start_date.format('YYYY-MM-DD');
            params['calendar_end_date'] = filters.calendar_end_date.format('YYYY-MM-DD');
        }
        return this.http.get<any>(url, {
            params: this.utilsService.removeUndefinedAndNullFromObject(params),
        });
    }

    getMyEvents(): Observable<EventModel[]> {
        return this.http.get<any>(`events/referrer_events/list`);
    }

    getUpcomingEvents(): Observable<EventModel[]> {
        return this.http.get<EventModel[]>('events/upcoming');
    }

    getEvent(id: number): Observable<EventModel> {
        const url = `events/${id}`;

        return this.http.get<EventModel>(url);
    }

    getAttachment(id: number): Observable<AttachmentModel> {
        const url = `event_attachments/${id}`;

        return this.http.get<AttachmentModel>(url);
    }

    getAgencies(agentId: number): Observable<AgencyModel[]> {
        const url = 'az_agent_agencies';
        const params = {
            az_agent_id: agentId.toString(),
        };

        return this.http.get<AgencyModel[]>(url, { params });
    }

    getCustomers(queryParam: string, val: any, subscription_id: number, is_from_italy: boolean): Observable<UserAnagraphicModel[]> {
        const url = `event_subscriptions/${subscription_id}/search_contact?${queryParam}=${val}&is_from_italy=${is_from_italy}`;

        return this.http.get<UserAnagraphicModel[]>(url);
    }

    getCustomersWithoutSubscription(
        queryParam: string,
        val: any,
        event_id: number,
        is_from_italy: boolean,
    ): Observable<UserAnagraphicModel[]> {
        const url = `events/${event_id}/search_contact?${queryParam}=${val}&is_from_italy=${is_from_italy}`;

        return this.http.get<UserAnagraphicModel[]>(url);
    }

    getQRCode(participantId: number): Observable<any> {
        return this.http.get<any>(`event_experience_subscription_participants/${participantId}/qrcode`);
    }

    getParticipantAttachment(id: number): Observable<any> {
        const url = `participant_attachments/${id}`;

        return this.http.get<any>(url);
    }

    getAdditionalInfoAttachment(id: number): Observable<any> {
        const url = `additional_info_answer_attachments/${id}`;

        return this.http.get<any>(url);
    }

    getEventTypes(categoryId: number, targetId: number | null | undefined, path: string = ''): Observable<any> {
        const url = `${path}events/allowed_types`;

        const params: any = {};
        params['category_ids'] = categoryId;

        if (targetId !== undefined || targetId !== null) {
            params['target_id'] = targetId;
        }

        return this.http.get<any>(url, { params: this.utilsService.removeUndefinedAndNullFromObject(params) });
    }

    getEventFilters(): Observable<FilteredConstantsModel> {
        return this.http.get<FilteredConstantsModel>(`events/filters`);
    }

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    saveTheDate(eventId: number): Observable<EventModel> {
        return this.http.post<EventModel>(`events/${eventId}/schedule`, '');
    }

    sendCustomerSatisfaction(formData: CustomerSatisfactionDataModel, subscriptionId: number): Observable<EventModel> {
        return this.http.post<EventModel>(`event_subscriptions/${subscriptionId}/customer_satisfaction`, formData);
    }

    sendInvitations(formData: Object, eventId: number): Observable<unknown> {
        return this.http.post<unknown>(`events/${eventId}/invite`, formData);
    }

    checkParticipantFiscalCode(formData: { [id: string]: string }, eventId: number): Observable<{ [id: string]: boolean }> {
        return this.http.post<{ [id: string]: boolean }>(`events/${eventId}/check_fiscal_code`, formData);
    }

    //
    // ─── HTTP REQUEST - PATCH ────────────────────────────────────────────────────────
    //

    //
    // ─── HTTP REQUEST - DELETE ────────────────────────────────────────────────────────
    //

    //
    // ─── RXJS ─────────────────────────────────────────────────────────────────────────
    //

    updateScheduledEvent(eventId: number): void {
        this.refreshScheduledEvent.next(eventId);
    }

    updateEvent(event: EventModel): void {
        this.refreshEvent.next(event);
    }

    updateListByFilterFromApi(action?: string | null): void {
        this.refreshListByFilterFromApi.next(action);
    }

    //
    // ─── UTILS ─────────────────────────────────────────────────────────────────────────
    //

    checkIfEventIsReserved(event: any, statusTag: string, categoryTag: string, targetTag: string): boolean {
        return (
            statusTag === Constants.EVENT_STATUSES_LIVE &&
            categoryTag === Constants.EVENT_CATEGORIES_PHYSICAL &&
            [Constants.EVENT_TARGETS_PUBLIC, Constants.EVENT_TARGETS_AZW_EMPLOYEE].includes(targetTag) &&
            event.has_subscription_closed
        );
    }
}
