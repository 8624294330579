import { Component } from '@angular/core';

@Component({
    selector: 'azd-layout-blank-page',
    template: `
        <azd-header [isBlankLayout]="true"></azd-header>
        <router-outlet></router-outlet>
        <azd-footer></azd-footer>
        <azd-error></azd-error>
    `,
    styles: []
})
export class LayoutBlankPageComponent {
}
