import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable, Subject, Subscriber, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';

import { Constants } from '@app/core/constants/constants';
import { AuthResponseModel } from '@app/shared/models/auth-response.model';
import { environment } from '@env/environment';
import { UtilsService } from '../services/base/utils.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService!: AuthService;
    private localStorageManagementService!: LocalStorageManagementService;
    private refreshTokenInProgress = false;

    private tokenRefreshedSource = new Subject<AuthResponseModel>();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private injector: Injector,
        private utilsService: UtilsService,
    ) {}

    addAuthHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
        if (localStorage.getItem(Constants.TOKEN_NAME)) {
            return request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem(Constants.TOKEN_NAME),
                },
            });
        }

        return request;
    }

    // Method for testing CRP login
    // addAuthUserId(request: HttpRequest<unknown>): HttpRequest<unknown> {
    //     return request.clone({
    //         setHeaders: {
    //             USERID: '00017567'
    //         }
    //     });
    // }

    refreshToken(): Observable<AuthResponseModel> {
        if (this.refreshTokenInProgress) {
            return new Observable((observer: Subscriber<AuthResponseModel>) => {
                this.tokenRefreshed$.subscribe((response: AuthResponseModel) => {
                    observer.next(response);
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.refreshToken().pipe(
                tap((response: AuthResponseModel) => {
                    this.tokenRefreshedSource.next(response);
                }),
                finalize(() => (this.refreshTokenInProgress = false)),
            );
        }
    }

    loginByLoginType(request: HttpRequest<unknown>, next: HttpHandler): any {
        const loginType = this.authService.getLoginTypeFromExternalUrl();

        switch (loginType) {
            case 'employee':
                return this.authService.employeeLogin().pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.authService.updateLoginError(error);
                        return EMPTY;
                    }),
                    switchMap(() => this.authService.getUser()),
                    catchError(() => {
                        this.localStorageManagementService.clearLocalStorage();
                        return EMPTY;
                    }),
                    switchMap(() => {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                    finalize(() => (this.refreshTokenInProgress = false)),
                );
            case 'agent':
            case 'consultant':
            case 'admin':
                return this.authService.crpLogin().pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.authService.updateLoginError(error);
                        return EMPTY;
                    }),
                    switchMap(() => this.authService.getUser()),
                    catchError(() => {
                        this.localStorageManagementService.clearLocalStorage();
                        return EMPTY;
                    }),
                    switchMap(() => {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                    finalize(() => (this.refreshTokenInProgress = false)),
                );
            default:
                this.localStorageManagementService.clearLocalStorage();
                return EMPTY;
        }
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authService = this.injector.get(AuthService);
        this.localStorageManagementService = this.injector.get(LocalStorageManagementService);

        if (!request.headers.get('Authorization')) {
            request = this.addAuthHeader(request);
        }

        // Method for testing CRP login
        // request = this.addAuthUserId(request);

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === 401 &&
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('token/refresh')
                ) {
                    if (this.localStorageManagementService.getRefreshToken()) {
                        return this.refreshToken().pipe(
                            tap((response: AuthResponseModel) => {
                                this.localStorageManagementService.setToken(response.token);
                                this.localStorageManagementService.setRefreshToken(response.refresh_token);
                            }),
                            catchError(() => {
                                return this.loginByLoginType(request, next);
                            }),
                            switchMap(() => {
                                request = this.addAuthHeader(request);
                                return next.handle(request);
                            }),
                            finalize(() => (this.refreshTokenInProgress = false)),
                        );
                    } else {
                        if (
                            [Constants.ENVIRONMENT_CRP_PREPROD, Constants.ENVIRONMENT_PROD].includes(environment.env) &&
                            this.utilsService.isPrivateHostname()
                        ) {
                            return this.loginByLoginType(request, next);
                        } else {
                            this.localStorageManagementService.clearLocalStorage();
                            this.authService.goToLogin();
                            return EMPTY;
                        }
                    }
                }
                return throwError(error);
            }),
        ) as Observable<HttpEvent<unknown>>;
    }
}
