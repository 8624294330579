<div class="azd-agentBox__rejectModal" nxModalContent>
    <div class="azd-agentBox__rejectModalWrapper">
        <div class="azd-agentBox__rejectModalTitle">
            <h3 nxHeadline="subsection-large">
                <span>{{event.name + ' - ' + dateTitle}}</span>
                {{ user.name + ' ' + user.surname }}
            </h3>
        </div>
        <p class="azd-agentBox__rejectModalConfirm">Confermi di voler annullare la richiesta?</p>
        <div class="azd-agentBox__rejectModalActions">
            <button nxButton='block secondary' (click)="toggleModal()" type="button">Annulla</button>
            <button nxButton='block primary' type="button" (click)="toggleModal(true)">Conferma
            </button>
        </div>
    </div>
</div>
