import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let apiReq: HttpRequest<unknown>;

        if (req.url.includes('i18n') || req.url.includes('https://') || req.url.includes('settings.json')) {
            apiReq = req;
        } else {
            apiReq = req.clone({
                url: `${environment.apiUrl}${req.url}`
            });
        }

        return next.handle(apiReq);
    }
}
