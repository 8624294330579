import * as crc32 from 'crc-32';

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { FeedbackService } from '@app/core/services/feedback.service';

import { Constants } from '@app/core/constants/constants';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { CustomValidators } from '@app/shared/validators/custom-validators';

import * as dayjs from 'dayjs';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

@Component({
    selector: 'azd-feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit, OnChanges {
    public constantsFE = Constants;
    public modalOpen = false;
    public isPending = false;
    public feedbackForm!: UntypedFormGroup;
    public options: UploaderOptions;
    public file!: UploadFile;
    public uploadInput: EventEmitter<UploadInput>;
    public humanizeBytes: Function;
    public fileTooLarge = false;
    public loggedUser: any;
    public userFeedbackTypes = [];
    public userFeedbackTypeById = [];
    public successSend = false;
    public code!: string | null;
    public OnBottom = false;
    public loadingApi = false;
    public userFeedbackTypeByTag: any;
    public filteredUserFeedbackTypes: any;
    public feedbackModal!: NxModalRef<any>;
    public userData: LoggedUserModel = this.authService.userLocalData;

    @Input() event!: any;
    @Input() isLink!: boolean;
    @Input() alwaysVisible = true;

    @ViewChild('textArea') textArea!: ElementRef;
    @ViewChild('feedbackModal') feedbackModalTemplateRef!: TemplateRef<any>;

    constructor(
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private formBuilder: UntypedFormBuilder,
        private feedbackService: FeedbackService,
        private errorService: ErrorService,
        private router: Router,
        public dialogService: NxDialogService,
        private authService: AuthService
    ) {
        this.options = { concurrency: 1, maxUploads: 3 };
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
    }

    ngOnInit() {
        this.loggedUser = JSON.parse(localStorage.getItem(Constants.USER) || '');
        this.getConstants();
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.populateForm();
    }

    getConstants() {
        this.constantsService.getConstants().subscribe(
            (response: any) => {
                this.userFeedbackTypes = response[Constants.USER_FEEDBACK_DIGITAL_TYPES];
                this.filteredUserFeedbackTypes = response[Constants.USER_FEEDBACK_DIGITAL_TYPES];
                this.userFeedbackTypeById = this.utilsService.arrayToObject(this.userFeedbackTypes, 'id');
                this.userFeedbackTypeByTag = this.utilsService.arrayToObject(this.userFeedbackTypes, 'tag');
            },
            (error) => {
                this.errorService.openModalError(error, 'Error fetching Constants');
            }
        );
    }

    toggleModal(resetForm: boolean = false) {
        if (resetForm) {
            this.successSend = false;
            this.isPending = false;
            this.code = null;

            this.createForm();
        }
        this.isPending = this.feedbackForm?.touched;
        this.toggleFeedbackModal();
    }

    toggleFeedbackModal() {
        this.feedbackModal = this.dialogService.open(this.feedbackModalTemplateRef, {
            showCloseIcon: true,
        });
    }

    createForm() {
        this.feedbackForm = this.formBuilder.group({
            name: [],
            email: [null, CustomValidators.validEmail],
            alternative_contact: [],
            user_feedback_type_id: [null, Validators.required],
            description: [null, Validators.required],
            event: [],
            hasDocument: [],
            attachment: this.formBuilder.group({
                document: [],
                id: [],
                type: [],
                name: [],
                hash: [],
                size: [],
                fileStream: [],
            }),
        });

        this.populateForm();
    }

    populateForm() {
        if (this.loggedUser) {
            this.feedbackForm.patchValue({
                name: this.loggedUser.name + ' ' + this.loggedUser.surname,
                email: this.loggedUser.email,
            });
        }
        if (this.event) {
            this.feedbackForm.patchValue({
                event: this.event.name + ' - ' + dayjs(this.event.start_date).format('DD/MM/YYYY'),
            });
        } else if (this.feedbackForm && this.userFeedbackTypeByTag) {
            let whatPageIs;
            if (this.router.url.includes('upcoming')) {
                whatPageIs = this.userFeedbackTypeByTag[Constants.USER_FEEDBACK_DIGITAL_TYPES_ACCESS_ALLIANZ_WORLD];
            } else if (this.router.url.includes('my-events')) {
                whatPageIs = this.userFeedbackTypeByTag[Constants.USER_FEEDBACK_DIGITAL_TYPES_ACCESS_ALLIANZ_WORLD];
            }
            this.feedbackForm.patchValue({
                event: whatPageIs?.description,
                user_feedback_type_id: whatPageIs?.id,
            });
        }
    }

    sendFeedback() {
        this.loadingApi = true;
        const formData = {
            name: this.feedbackForm.get('name')?.value,
            email: this.feedbackForm.get('email')?.value,
            alternative_contact: this.feedbackForm.get('alternative_contact')?.value,
            user_feedback_type_id: this.feedbackForm.get('user_feedback_type_id')?.value,
            description: this.textArea.nativeElement.value,
            event: this.feedbackForm.get('event')?.value,
            attachments:
                this.feedbackForm.get('hasDocument')?.value === 'si' && this.feedbackForm.get('attachment.fileStream')?.value
                    ? [
                          {
                              name: this.feedbackForm.get('attachment.name')?.value,
                              hash: this.feedbackForm.get('attachment.hash')?.value,
                              filestream: this.feedbackForm.get('attachment.fileStream')?.value,
                          },
                      ]
                    : null,
        };
        this.feedbackService.sendFeedback(formData).subscribe(
            (response) => {
                this.successSend = true;
                this.code = response.azw_user_feedback_code;
                this.loadingApi = false;
            },
            (error) => {
                this.loadingApi = false;
                this.errorService.openModalError(error, 'Error sending feedback');
            }
        );
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            if (output.file.size > Constants.MAX_FILE_SIZE) {
                this.fileTooLarge = true;
                this.removeFile(output.file.id);
            } else {
                this.fileTooLarge = false;
                this.calculateHash(output.file);
            }
        } else if (output.type === 'rejected') {
            this.feedbackForm.get('attachment')?.patchValue({
                document: null,
                id: null,
                type: null,
                name: null,
                hash: null,
                size: null,
                fileStream: null,
            });
        }
    }

    lpad(s: any, len: any, chr: any) {
        const L = len - s.length;
        const C = chr || ' ';
        if (L <= 0) {
            return s;
        }
        return new Array(L + 1).join(C) + s;
    }

    calculateHash(file: UploadFile): void {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const fileResult = new Uint8Array(fileReader.result as ArrayBuffer);
            // tslint:disable-next-line:no-bitwise
            const hash = this.lpad((crc32.buf(fileResult) >>> 0).toString(16), 8, '0').toUpperCase();
            this.uploadDocument(file, hash);
        };
        fileReader.readAsArrayBuffer(file.nativeFile as Blob);
    }

    uploadDocument(file: UploadFile, hash: string): void {
        const myReader: FileReader = new FileReader();
        myReader.onloadend = () => {
            if (myReader.result) {
                const tmpB64String = myReader.result.toString().split(',')[1];

                this.feedbackForm.get('attachment')?.patchValue({
                    document: null,
                    id: file.id,
                    type: file.type,
                    name: file.name,
                    hash: hash,
                    size: file.size,
                    fileStream: tmpB64String,
                });
            }
        };
        myReader.readAsDataURL(file.nativeFile as Blob);
    }

    removeFile(id: any): void {
        this.uploadInput.emit({ type: 'remove', id: id });
        this.feedbackForm.get('attachment')?.patchValue({
            document: null,
            id: null,
            type: null,
            name: null,
            hash: null,
            size: null,
            fileStream: null,
        });
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const pos = window.innerHeight + window.scrollY;
        const max = document.documentElement.scrollHeight;
        this.OnBottom = pos >= (max + window.innerHeight) / 2;
    }
}
