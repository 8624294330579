import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    constructor(private http: HttpClient) {}

    //
    // ─── HTTP REQUEST - GET ────────────────────────────────────────────────────────
    //

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    sendFeedback(formData: any): Observable<any> {
        return this.http.post<any>(`user_feedback`, formData).pipe(tap((response) => {}));
    }

    //
    // ─── HTTP REQUEST - PATCH ────────────────────────────────────────────────────────
    //
}
