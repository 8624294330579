import { Component, Input, OnInit } from '@angular/core';

import { ConstantsService } from '@app/core/services/base/constants.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { EventService } from '@app/core/services/event.service';
import { ExperienceService } from '@app/core/services/experience.service';

import { ConstantsMapModel, ConstantsModel } from '@app/shared/models/constant.model';
import { AttachmentModel } from '@app/shared/models/attachment.model';
import { Constants } from '@app/core/constants/constants';

@Component({
    selector: 'azd-attachment-box',
    templateUrl: './attachment-box.component.html',
    styleUrls: ['./attachment-box.component.scss', './attachment-box-mobile.component.scss']
})
export class AttachmentBoxComponent implements OnInit {
    @Input() attachment!: AttachmentModel;
    @Input() constantType!: string;
    @Input() is_experience!: boolean;
    public constantsFE!: Constants;
    public constantById!: ConstantsMapModel;
    public label!: string;

    constructor(
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private errorService: ErrorService,
        private eventService: EventService,
        private experienceService: ExperienceService
    ) {
    }

    ngOnInit(): void {
        this.getConstants();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: ConstantsModel) => {
                this.constantById = this.utilsService.arrayToObject(response[this.constantType], 'id');

                this.getAttachmentDetail();
            },
            error => {
                this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
            }
        );
    }

    getAttachmentDetail(): void {
        this.label = 'CONSTANTS.' + this.constantType + '.' + this.constantById[this.attachment.type_id].tag;
    }

    onDownloadClick(): void {
        const serviceCall = this.is_experience ? this.experienceService : this.eventService;

        serviceCall.getAttachment(this.attachment.id).subscribe(
            response => {
                const linkSource = `data:application/octet-stream;base64,${response.filestream}`;
                const downloadLink = document.createElement('a');

                downloadLink.href = linkSource;
                downloadLink.download = this.attachment.name || 'allegato.pdf';
                downloadLink.click();
                downloadLink.remove();
            },
            error => this.errorService.openModalError(error, 'Errore nel caricamento dell\'allegato')
        );
    }

}
