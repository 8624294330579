import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: string, args?: string[] | undefined): string {
        const limit = args && args.length > 0 ? parseInt(args[0], 10) : 10;
        const trail = args && args.length > 1 ? args[1] : '...';

        if (value === null) {
            return value;
        }

        return value.length > limit ? value.substring(0, limit) + trail : value;
    }

}
