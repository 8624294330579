<div class="azd-attachmentBox nx-margin-top-s">
    <b class="nx-font-weight-semibold nx-margin-bottom-s">{{this.label | translate}}</b>
    <div class="azd-attachmentBox__box">
        <div class="azd-attachmentBox__boxInfo nx-font-weight-light">
            <b>{{attachment.name | truncate:['15']}}</b>
            <span>{{attachment.size | bytesToSize}}</span>
        </div>
        <button nxIconButton="tertiary" aria-label="Donwload" type="button" (click)="onDownloadClick()">
            <nx-icon name="download"></nx-icon>
        </button>
    </div>
</div>
