import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { Constants } from '@app/core/constants/constants';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class DevEnvironmentGuard {
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        switch (environment.env) {
            case Constants.ENVIRONMENT_LOCAL:
            case Constants.ENVIRONMENT_DEV:
            case Constants.ENVIRONMENT_QA:
            case Constants.ENVIRONMENT_STAGING:
            case Constants.ENVIRONMENT_CRP_DEV:
            case Constants.ENVIRONMENT_CRP_TEST:
                return true;
            default:
                return false;
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }
}
