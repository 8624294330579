<div class="azd-modal azd-profileModal">
    <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" class="azd-modal__logo" />

    <form [formGroup]="modalForm" autocomplete="off">
        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_TYPE_LOGIN">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Accedi come:</h3>
                <div nxLayout="grid">
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="goToLink(env.agentPortalUrl)">Agente Allianz</button>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="goToLink(env.consultantPortalUrl)">
                                Consulente Finanziario Allianz Bank
                            </button>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="onSetCurrentStepClick(nextStep)">
                                Cliente o altro invitato
                            </button>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="goToLink(env.employeePortalUrl)">Dipendente</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_LOGIN">
            <div class="azd-modal__content" nxModalContent formGroupName="login">
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Login</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Email">
                                <input type="email" name="email" formControlName="email" nxInput required />
                                <nx-error nxFormfieldError> Campo email obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Password">
                                <input type="password" #loginPassword name="password" formControlName="password" nxInput required />
                                <nx-password-toggle
                                    [control]="loginPassword"
                                    ariaLabel="Visualizza password"
                                    nxFormfieldSuffix
                                ></nx-password-toggle>
                                <nx-error nxFormfieldError> Campo password obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-checkbox name="remember_me" formControlName="remember_me">Ricordami</nx-checkbox>
                                <nx-link>
                                    <a [routerLink]="null" (click)="onSetCurrentStepClick(constantsFE.PROFILE_MODAL_STEP_FORGOT_PASSWORD)">
                                        Recupera password
                                    </a>
                                </nx-link>
                            </div>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="onLoginClick()" [disabled]="modalForm.get('login')?.invalid">
                                Accedi
                            </button>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12" class="azd-text-align-center">
                            <span nxCopytext="normal" class="nx-font-weight-bold">OPPURE</span>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <button
                                nxButton="primary"
                                type="button"
                                (click)="onSetCurrentStepClick(constantsFE.PROFILE_MODAL_STEP_PERSONAL_DATA)"
                            >
                                Registrati
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_FORGOT_PASSWORD">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Recupera password</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Codice fiscale">
                                <input type="text" name="recovery_fiscal_code" formControlName="recovery_fiscal_code" nxInput required />
                                <nx-error nxFormfieldError> Il codice fiscale inserito non è corretto o formalmente valido. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow *ngIf="error">
                        <div nxCol="12">
                            <nx-error> Il codice fiscale inserito non corrisponde a nessun utente registrato in piattaforma. </nx-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary" type="button" (click)="onSetCurrentStepClick(constantsFE.PROFILE_MODAL_STEP_LOGIN)">
                    Indietro
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="modalForm.get('recovery_fiscal_code')?.invalid"
                    (click)="onRecoveryPasswordClick()"
                >
                    continua
                </button>
            </div>
        </ng-container>

        <!-- TODO: OUT OF MVP -->
        <!--<ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_IS_PARTICIPANT">
            <div class="azd-modal__content" nxModalContent formGroupName="profile">
                <h3 nxHeadline="subsection-large"
                    class="azd-modal__contentModalTitle">Partecipante</h3>
                <div nxLayout="grid" formGroupName="is_customer_step">
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-label class="nx-margin-top-s nx-margin-right-s">
                                    <span nxCopytext="normal"
                                          class="nx-font-weight-bold">Sei già un cliente Allianz?</span>
                                </nx-label>
                                <nx-radio-group name="is_allianz_customer" formControlName="is_allianz_customer">
                                    <div class="azd-horizontal nx-margin-top-s">
                                        <nx-radio [nxValue]="true">Si</nx-radio>
                                        <nx-radio [nxValue]="false" class="nx-margin-left-s">No</nx-radio>
                                    </div>
                                </nx-radio-group>
                            </div>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-label class="nx-margin-top-s nx-margin-right-s">
                                <span nxCopytext="normal"
                                      class="nx-font-weight-bold">Sei già un cliente Allianz Bank?</span>
                                </nx-label>
                                <nx-radio-group name="is_allianz_bank_customer"
                                                formControlName="is_allianz_bank_customer">
                                    <div class="azd-horizontal nx-margin-top-s">
                                        <nx-radio [nxValue]="true">Si</nx-radio>
                                        <nx-radio [nxValue]="false" class="nx-margin-left-s">No</nx-radio>
                                    </div>
                                </nx-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="secondary"
                        type="button"
                        (click)="onSetCurrentStepClick(constantsFE.PROFILE_MODAL_STEP_LOGIN)">Indietro
                </button>
                <button nxButton="primary"
                        type="button"
                        (click)="onSetCurrentStepClick(constantsFE.PROFILE_MODAL_STEP_PERSONAL_DATA)">continua
                </button>
            </div>
        </ng-container>-->

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_PERSONAL_DATA">
            <div class="azd-modal__content" nxModalContent formGroupName="profile">
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Creazione profilo</h3>
                <div nxLayout="grid" formGroupName="personal_data_step">
                    <div nxRow>
                        <div nxCol="6">
                            <nx-formfield label="Nome *">
                                <input type="text" name="name" formControlName="name" nxInput required />
                                <nx-error nxFormfieldError> Campo nome obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="6">
                            <nx-formfield label="Cognome *">
                                <input type="text" name="surname" formControlName="surname" nxInput required />
                                <nx-error nxFormfieldError> Campo cognome obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Il tuo codice fiscale *">
                                <input type="text" name="fiscal_code" formControlName="fiscal_code" nxInput required />
                                <nx-error
                                    nxFormfieldError
                                    *ngIf="modalForm.get('profile.personal_data_step.fiscal_code')?.hasError('required')"
                                >
                                    Campo codice fiscale obbligatorio.
                                </nx-error>
                                <nx-error
                                    nxFormfieldError
                                    *ngIf="
                                        modalForm.get('profile.personal_data_step.fiscal_code')?.hasError('validFiscalCode') &&
                                        modalForm.get('profile.personal_data_step.fiscal_code')?.value
                                    "
                                >
                                    Il codice fiscale non è formalmente valido.
                                </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="La tua email *">
                                <input type="email" name="email" formControlName="email" nxInput required autocomplete="nope" />
                                <nx-error nxFormfieldError> Campo email obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Conferma email *">
                                <input
                                    type="email"
                                    name="confirm_email"
                                    formControlName="confirm_email"
                                    nxInput
                                    required
                                    autocomplete="nope"
                                />
                                <nx-error nxFormfieldError> Le due email non coincidono. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="La tua password *">
                                <input
                                    type="password"
                                    #personalDataPassword
                                    name="password"
                                    formControlName="password"
                                    nxInput
                                    required
                                    autocomplete="new-password"
                                />
                                <nx-password-toggle
                                    [control]="personalDataPassword"
                                    ariaLabel="Visualizza password"
                                    nxFormfieldSuffix
                                ></nx-password-toggle>
                                <nx-error
                                    nxFormfieldError
                                    *ngIf="modalForm.get('profile.personal_data_step.password')?.hasError('validPassword')"
                                >
                                    La password deve avere minimo 10 caratteri, massimo 20 caratteri, almeno una lettera minuscola e una
                                    maiuscola, almeno un carattere speciale tra i seguenti
                                    <span style="font-weight: bold">!%&#64;#$^*?_-.~()=</span> e almeno un valore numerico.
                                </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Conferma password *">
                                <input
                                    type="password"
                                    name="confirm_password"
                                    formControlName="confirm_password"
                                    nxInput
                                    required
                                    autocomplete="new-password"
                                />
                                <nx-error nxFormfieldError> Le due password non coincidono. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <hr />
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-label class="nx-margin-top-s nx-margin-right-s">
                                    <span nxCopytext="normal" class="nx-font-weight-bold">Informativa privacy</span>
                                </nx-label>
                                <nx-link class="nx-margin-top-s">
                                    <a [routerLink]="null" nxCopytext="medium" (click)="onOpenFileClick()">
                                        <nx-icon name="file-text" class="nx-margin-right-xs"></nx-icon>
                                        Leggi di più
                                    </a>
                                </nx-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button
                    nxButton="secondary"
                    type="button"
                    [disabled]="loading"
                    (click)="onSetCurrentStepClick(!isFromLink ? constantsFE.PROFILE_MODAL_STEP_LOGIN : undefined)"
                >
                    {{ isFromLink ? 'Annulla' : 'Indietro' }}
                </button>
                <button
                    nxButton="primary"
                    type="button"
                    [disabled]="modalForm.get('profile.personal_data_step')?.invalid || loading"
                    (click)="onCreateProfileClick()"
                >
                    Registrati
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_PENDING">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Completa la registrazione!</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-profileModal__content">
                                <nx-icon name="exclamation-triangle-o" size="l"></nx-icon>
                                <p>
                                    Per completare la registrazione alla piattaforma clicca sul link che ti abbiamo inviato all’indirizzo
                                    email {{ censorEmail }}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="primary" type="button" nxModalClose>Chiudi</button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_SUCCESS">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Registrazione avvenuta con successo!</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-profileModal__content">
                                <nx-icon name="check" size="l"></nx-icon>
                                <p *ngIf="!userData">
                                    clicca
                                    <a href="javascript:void(0)" (click)="currentStep = constantsFE.PROFILE_MODAL_STEP_LOGIN"><u>qui</u></a>
                                    per accedere alla piattaforma
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="primary" type="button" nxModalClose>Chiudi</button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_RECOVERY_SUCCESS">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">
                    Richiesta di recupero password<br />avvenuta con successo!
                </h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-profileModal__content">
                                <nx-icon name="check" size="l"></nx-icon>
                                <p>
                                    Procedi con il recupero della password tramite il link che ti abbiamo inviato all'indirizzo email:
                                    {{ censorEmail }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="primary" type="button" nxModalClose>Chiudi</button>
            </div>
        </ng-container>

        <ng-container *ngIf="currentStep === constantsFE.PROFILE_MODAL_STEP_RESET_PASSWORD_SUCCESS">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Modifica password avvenuta con successo!</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-profileModal__content">
                                <nx-icon name="check" size="l"></nx-icon>
                                <p>
                                    clicca
                                    <a href="javascript:void(0)" (click)="currentStep = constantsFE.PROFILE_MODAL_STEP_LOGIN"><u>qui</u></a>
                                    per accedere alla piattaforma
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="azd-modal__footer" nxModalActions>
                <button nxButton="primary" type="button" nxModalClose>Chiudi</button>
            </div>
        </ng-container>
    </form>
</div>
