import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { ErrorService } from '@app/core/services/base/error.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { EventService } from '@app/core/services/event.service';

import { Constants } from '@app/core/constants/constants';
import { AgencyModel } from '@app/shared/models/agency.model';
import { ConstantsMapModel, ConstantsModel } from '@app/shared/models/constant.model';
import { DataModalModel } from '@app/shared/models/data-modal.model';
import { EventModel } from '@app/shared/models/event.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { UserAnagraphicModel } from '@app/shared/models/user.model';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
    selector: 'azd-share-event-modal',
    templateUrl: './share-event-modal.component.html',
    styleUrls: ['./share-event-modal.component.scss'],
})
export class ShareEventModalComponent implements OnInit {
    public constantsFE = Constants;
    public currentStep = 1;
    public modalForm!: UntypedFormGroup;
    public loading = false;
    public showPanel!: string;
    public customers: UserAnagraphicModel[] = [];
    public canInvite = true;
    public getCustomersSubscription!: Subscription;
    public invitations: any[] = [];
    public selectedCustomer!: UserAnagraphicModel | null;
    public subscriberAgencies: AgencyModel[] = [];
    public displayAlternativeEmailHint = false;
    public event!: EventModel;
    public isAZCandidacy!: boolean;
    public commercialStructureById!: ConstantsMapModel;
    public userData: LoggedUserModel = this.authService.userLocalData;
    public roleById!: ConstantsMapModel;
    public participantTypeByTag!: ConstantsMapModel;
    public participantTypeId!: number | null;

    constructor(
        @Inject(NX_MODAL_DATA) public data: DataModalModel,
        private errorService: ErrorService,
        private dialogService: NxDialogService,
        private formBuilder: UntypedFormBuilder,
        private eventService: EventService,
        private dialog: NxModalRef<ShareEventModalComponent>,
        private constantsService: ConstantsService,
        private utilsService: UtilsService,
        private authService: AuthService
    ) {
        if (data && Object.keys(data).length !== 0) {
            if (data.event) {
                this.event = data.event;
            }
        }
    }

    ngOnInit(): void {
        this.getConstants();
        this.createForm();
        this.settingUpModal();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: ConstantsModel) => {
                this.commercialStructureById = this.utilsService.arrayToObject(response[Constants.COMMERCIAL_STRUCTURES], 'id');
                this.roleById = this.utilsService.arrayToObject(response[Constants.USER_ROLES], 'id');
                this.participantTypeByTag = this.utilsService.arrayToObject(
                    response[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES],
                    'tag'
                );
            },
            (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Errore nel caricamento delle constanti');
            }
        );
    }

    createForm(): void {
        this.modalForm = this.formBuilder.group({
            id: [],
            name: [null, Validators.required],
            surname: [null, Validators.required],
            fiscal_code: [null, CustomValidators.validFiscalCode],
            az_agency_id: [],
            email: [null, CustomValidators.validEmail],
            alternative_email: [null, CustomValidators.validEmailNotRequired],
        });
    }

    settingUpModal(): void {
        if (this.event.subscription !== null) {
            this.isAZCandidacy =
                this.commercialStructureById[this.event.subscription.commercial_structure_id].tag === Constants.COMMERCIAL_STRUCTURES_AZ;
        } else {
            if (this.userData.role_id !== undefined) {
                this.isAZCandidacy = Constants.USER_ROLES_AZ_GROUP.includes(this.roleById[this.userData.role_id].tag);
            }
        }

        if (this.isAZCandidacy) {
            this.getSubscriberAgencies();
        }
    }

    setAgencyRequired(): void {
        if (this.isAZCandidacy) {
            this.modalForm.get('az_agency_id')!.setValidators(Validators.required);
            this.modalForm.get('az_agency_id')!.updateValueAndValidity();

            if (this.subscriberAgencies && this.subscriberAgencies.length === 1) {
                this.modalForm.get('az_agency_id')!.setValue(this.subscriberAgencies[0].id);
            }
        }
    }

    getSubscriberAgencies(): void {
        const agentId =
            this.event.subscription && this.event.subscription.subscriber.id !== null
                ? this.event.subscription.subscriber.id
                : this.userData.id;

        this.eventService.getAgencies(agentId).subscribe((agencies: AgencyModel[]) => {
            this.subscriberAgencies = agencies;

            this.setAgencyRequired();
        });
    }

    onInputFocusOut(): void {
        setTimeout(() => {
            this.showPanel = '';
        }, 300);
    }

    onGetCustomerKeyUp(queryParam: string, e: any): void {
        const val = e.target.value.trim();

        this.canInvite = true;
        this.modalForm.get('email')!.reset();
        this.modalForm.get('alternative_email')!.reset();
        this.modalForm.get('email')!.enable();
        this.modalForm.get('alternative_email')!.disable();

        if (!val) {
            this.customers = [];
            this.showPanel = '';
            return;
        }
        if (this.getCustomersSubscription) {
            this.getCustomersSubscription.unsubscribe();
        }
        if (queryParam === 'full_name' || (queryParam === 'fiscal_code' && val.length >= 16)) {
            if (this.event.subscription) {
                this.getCustomersSubscription = this.eventService
                    .getCustomers(queryParam, val, this.event.subscription!.id, false)
                    .subscribe(
                        (response: UserAnagraphicModel[]) => {
                            this.afterGetCustomers(response, queryParam);
                        },
                        (error: HttpErrorResponse) => {
                            this.errorService.openModalError(error, 'Errore nella ricerca customer');
                        }
                    );
            } else {
                this.getCustomersSubscription = this.eventService
                    .getCustomersWithoutSubscription(queryParam, val, this.event.id, false)
                    .subscribe(
                        (response: UserAnagraphicModel[]) => {
                            this.afterGetCustomers(response, queryParam);
                        },
                        (error: HttpErrorResponse) => {
                            this.errorService.openModalError(error, 'Errore nella ricerca customer');
                        }
                    );
            }
        }
    }

    afterGetCustomers(users: UserAnagraphicModel[], queryParam: string): void {
        if (users && users.length !== 0) {
            this.customers = users.filter(
                (customer: UserAnagraphicModel) =>
                    !this.invitations.map((invitation) => invitation.fiscal_code).includes(customer.fiscal_code)
            );
            this.showPanel = this.customers.length !== 0 ? queryParam : '';
        } else {
            this.customers = [];
            this.showPanel = '';
        }
    }

    onSetCustomerClick(customer: UserAnagraphicModel): void {
        this.participantTypeId = null;
        this.selectedCustomer = customer;
        this.showPanel = '';
        this.modalForm.patchValue({
            id: this.selectedCustomer.id,
            name: this.selectedCustomer.name,
            surname: this.selectedCustomer.surname,
            fiscal_code: this.selectedCustomer.fiscal_code,
            email: this.selectedCustomer.email,
        });
        if (!this.selectedCustomer.can_be_updated) {
            this.modalForm.get('email')!.disable();
        } else {
            this.modalForm.get('email')!.enable();
        }
        this.modalForm.get('alternative_email')!.enable();

        if (this.isAZCandidacy) {
            const azAgencyId = this.selectedCustomer.az_agency_id
                ? this.selectedCustomer.az_agency_id
                : this.subscriberAgencies.length === 1
                ? this.subscriberAgencies[0].id
                : null;

            if (azAgencyId !== null) {
                this.modalForm.get('az_agency_id')?.setValue(azAgencyId);
            }
        }

        if (this.selectedCustomer.is_from_ws) {
            this.participantTypeId = this.participantTypeByTag[Constants.EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_CUSTOMER].id;
        }
    }

    onAddInvitationClick(): void {
        const formData = {
            fiscal_code: this.modalForm.get('fiscal_code')!.value?.toUpperCase(),
        };

        if (this.isPresentOnList()) {
            this.canInvite = false;
            return;
        }

        this.loading = true;
        this.eventService.checkParticipantFiscalCode(formData, this.event.id).subscribe(
            (response: { [id: string]: boolean }) => {
                this.canInvite = response.can_invite;
                if (this.canInvite) {
                    let data = this.modalForm.getRawValue();

                    if (data) {
                        if (this.participantTypeId !== null) {
                            data = {
                                ...data,
                                participant_type_id: this.participantTypeId,
                            };
                        }
                        this.invitations.push(data);
                    }
                    this.selectedCustomer = null;
                    this.modalForm.get('email')!.enable();
                    this.modalForm.reset();
                }
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.errorService.openModalError(error, 'Errore nella ricerca del codice fiscale');
            }
        );
    }

    onRemoveInvitationClick(invitationIndex: number): void {
        this.invitations.splice(invitationIndex, 1);
    }

    onSendInvitationsClick(): void {
        const formData = {
            invitations: this.invitations.map((invitation) => {
                let data;

                data = {
                    name: invitation.name,
                    surname: invitation.surname,
                    fiscal_code: invitation.fiscal_code?.toUpperCase(),
                    az_agency_id: invitation.az_agency_id,
                    alternative_email: invitation.alternative_email,
                    email: invitation.email,
                };

                if (![null, undefined].includes(invitation.participant_type_id)) {
                    data = {
                        ...data,
                        participant_type_id: invitation.participant_type_id,
                    };
                }

                return data;
            }),
        };

        this.loading = true;
        this.eventService.sendInvitations(formData, this.event.id).subscribe(
            () => {
                this.loading = false;
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.errorService.openModalError(error, "Errore nell'invio degli inviti");
            }
        );
    }

    closeModal(): void {
        this.dialog.close();
    }

    isPresentOnList(): boolean {
        return this.invitations
            .map((invitation) => invitation.fiscal_code.toLowerCase())
            .includes(this.modalForm.get('fiscal_code')!.value.toLowerCase());
    }
}
