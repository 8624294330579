import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards/auth.guards';

import { LayoutPageComponent } from '@app/shared/layouts/layout-page.component';
import { LayoutBlankPageComponent } from '@app/shared/layouts/layout-blank-page.component';

const routes: Routes = [
    { path: '', redirectTo: 'upcoming', pathMatch: 'full' },
    {
        path: '',
        component: LayoutPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./features/events/events.module').then(m => m.EventsModule)
            },
        ]
    },
    {
        path: '',
        component: LayoutPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./features/my-account/my-account.module').then(m => m.MyAccountModule)
            },
        ]
    },
    {
        path: '',
        component: LayoutBlankPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./features/confirmation/confirmation.module').then(m => m.ConfirmationModule)
            },
        ]
    },
    { path: '**', redirectTo: 'upcoming' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
