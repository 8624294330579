export const environment = {
    production: false,
    env: 'dev',
    apiUrl: 'https://dev-api-azw-event-management.h-en.me/api-azworld/digital/',
    privateUrl: '',
    consultantUrl: '',
    agentPortalUrl: 'https://portaleagenzie.allianz.it/azworld-d?logintype=agent',
    consultantPortalUrl: 'https://consulente.allianzbank.it/azworld-d',
    employeePortalUrl: 'https://portaleagenzie.allianz.it/azworld-d?logintype=employee',
    debug: 'ERROR', // TRACE|DEBUG|INFO|LOG|WARN|ERROR|OFF
    debugServer: 'OFF', // TRACE|DEBUG|INFO|LOG|WARN|ERROR|OFF
    googleMapsApiKey: 'AIzaSyA5pkpTIBQM8aI5nVeMew5IeCrTjHa3VWw'
};
