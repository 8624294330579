import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';

import { ErrorService } from '@app/core/services/base/error.service';
import { BulletinMessagesService } from '@app/core/services/bulletin-messages.service';

import { BulletinMessage } from '@app/shared/models/bulletin-message.model';

@Component({
    selector: 'azd-user-bulletin-messages-modal',
    templateUrl: './user-bulletin-messages-modal.component.html',
    styleUrls: ['./user-bulletin-messages-modal.component.scss'],
})
export class UserBulletinMessagesModalComponent implements OnInit {
    public messagesModal!: NxModalRef<any>;
    public messages: BulletinMessage[] = [];
    public hideMessages = false;

    @ViewChild('messagesModalTemplate') messagesModalTemplate!: TemplateRef<any>;

    constructor(
        //
        private bulletinMessagesService: BulletinMessagesService,
        private errorService: ErrorService,
        private dialogService: NxDialogService
    ) {}
    ngOnInit(): void {
        this.getUserMessages();
    }

    openModal(): void {
        this.messagesModal = this.dialogService.open(this.messagesModalTemplate, {
            disableClose: true,
            showCloseIcon: false,
            maxHeight: '100vh',
        });

        this.messagesModal.afterClosed().subscribe(() => {
            if (this.hideMessages) {
                this.hideUserMessages();
            }
        });
    }

    getUserMessages(): void {
        this.bulletinMessagesService.getUserMessages().subscribe({
            next: (messages: BulletinMessage[]) => {
                this.messages = messages.map((m) => {
                    return { ...m, message: m.message.replace(/(?:\r\n|\r|\n)/g, '<br>') };
                });

                if (this.messages.length > 0) {
                    this.openModal();
                }
            },
            error: (error: HttpErrorResponse) => this.errorService.openModalError(error, 'Error fetching User Messages'),
        });
    }

    hideUserMessages(): void {
        this.bulletinMessagesService.hideUserMessages().subscribe({
            next: () => {},
            error: (error: HttpErrorResponse) => this.errorService.openModalError(error, 'Error patch User Messages'),
        });
    }
}
