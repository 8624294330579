<div class="azd-modal azd-loginModal">
    <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" class="azd-modal__logo" />

    <form [formGroup]="modalForm" autocomplete="off">
        <ng-container *ngIf="currentStep === constantsFE.LOGIN_MODAL_STEP_LOGIN">
            <div class="azd-modal__content" nxModalContent>
                <h3 nxHeadline="subsection-large" class="azd-modal__contentModalTitle">Login</h3>
                <div nxLayout="grid">
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Username">
                                <input type="text" name="text" formControlName="username" nxInput required />
                                <nx-error nxFormfieldError> Campo username obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <nx-formfield label="Password">
                                <input type="password" #loginPassword name="password" formControlName="password" nxInput required />
                                <nx-password-toggle
                                    [control]="loginPassword"
                                    ariaLabel="Visualizza password"
                                    nxFormfieldSuffix
                                ></nx-password-toggle>
                                <nx-error nxFormfieldError> Campo password obbligatorio. </nx-error>
                            </nx-formfield>
                        </div>
                    </div>
                    <div nxRow>
                        <div nxCol="12">
                            <div class="azd-element-in-a-row">
                                <nx-checkbox name="remember_me" formControlName="remember_me">Ricordami</nx-checkbox>
                            </div>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="onLoginClick()" [disabled]="modalForm.invalid">Accedi</button>
                        </div>
                    </div>
                    <div nxRow class="nx-margin-y-m">
                        <div nxCol="12">
                            <button nxButton="primary" type="button" (click)="onLoginClick(true)" [disabled]="modalForm.invalid">
                                Accedi come dipendente
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</div>
