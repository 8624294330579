<header nx-header class="azd-header">
    <nx-header-brand>
        <nx-link>
            <a routerLink="/">
                <figure nxFigure>
                    <img src="assets/images/logoAZ-world-of-event-L.png" alt="Allianz World of Events" role="none" />
                </figure>
            </a>
        </nx-link>
    </nx-header-brand>

    <nx-header-navigation [class.-open]="menuOpen" *ngIf="!isBlankLayout">
        <nx-header-navigation-item>
            <a routerLink="/upcoming" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-active" nxHeaderLink
                >Eventi in programma</a
            >
        </nx-header-navigation-item>
        <nx-header-navigation-item *ngIf="userData">
            <a
                routerLink="/my-events"
                *permissionsOnly="constantsFE.USER_ROLES_AGENT_GROUP"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="is-active"
                nxHeaderLink
                >I tuoi eventi</a
            >
        </nx-header-navigation-item>
        <azd-header-user-detail *ngIf="userData" class="--mobileOnly"></azd-header-user-detail>
    </nx-header-navigation>

    <nx-header-actions *ngIf="!isBlankLayout">
        <!--<button nxPlainButton class="nx-margin-left-s">
            <nx-icon name='bell-o' aria-label="Notifiche"></nx-icon>
        </button>-->
        <button nxPlainButton class="nx-margin-left-s" *ngIf="!userData && isDevEnv" (click)="onShowLoginModalClick()">
            <nx-icon name="lock-o" aria-label="Login come agente / Ufficio eventi"></nx-icon>
        </button>
        <button nxPlainButton *ngIf="!userData || !isAzwAccount" class="nx-margin-left-s --mobileOnly">
            <nx-link>
                <a [routerLink]="userData && !isAzwAccount ? '/my-account' : null" (click)="onShowProfileModalClick()">
                    <nx-icon name="user-o" aria-label="Il mio account"></nx-icon>
                </a>
            </nx-link>
        </button>
        <div class="azd-vertical-hr --desktopOnly"></div>
        <button nxPlainButton class="nx-margin-left-2xs --mobileOnly" (click)="onShowMenuClick()">
            <nx-icon name="bars" aria-label="Menu"></nx-icon>
        </button>
        <nx-link class="--desktopOnly" *ngIf="!userData">
            <a [routerLink]="null" (click)="onShowProfileModalClick()">Accedi / Registrati</a>
        </nx-link>
        <azd-header-user-detail *ngIf="userData" class="--desktopOnly"></azd-header-user-detail>
    </nx-header-actions>
</header>

<azd-user-bulletin-messages-modal *ngIf="userData"></azd-user-bulletin-messages-modal>
