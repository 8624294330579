import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';

import { ConstantsModel } from '@app/shared/models/constant.model';
import { LoggedUserModel } from '@app/shared/models/logged-user.model';
import { Constants } from '@app/core/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class RoleBasedPermissionsService {

    constructor(
        private constantsService: ConstantsService,
        private authService: AuthService,
    ) {

    }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    checkAuthorizationOnly(authorizedRolesTags: string | string[]): Observable<boolean> {
        return Observable.create((observer: Observer<unknown>) => {
            this.constantsService.getConstants().subscribe(
                (constants: ConstantsModel) => {
                    this.authService.getUser().subscribe(
                        (userData: LoggedUserModel) => {
                            const userRole = constants[Constants.USER_ROLES].find(
                                role => role.id === userData.role_id
                            );
                            if (userRole) {
                                observer.next(this.checkPermissionOnly(userRole.tag, authorizedRolesTags));
                            }
                        }
                    );
                }
            );
        });
    }

    checkAuthorizationExcept(authorizedRolesTags: string | string[]): Observable<boolean> {
        return Observable.create((observer: Observer<unknown>) => {
            this.constantsService.getConstants().subscribe(
                (constants: ConstantsModel) => {
                    this.authService.getUser().subscribe(
                        (userData: LoggedUserModel) => {
                            const userRole = constants[Constants.USER_ROLES].find(
                                role => role.id === userData.role_id
                            );
                            if (userRole) {
                                observer.next(this.checkPermissionExcept(userRole.tag, authorizedRolesTags));
                            } else {
                                observer.next(true);
                            }
                        }
                    );
                }
            );
        });
    }

    checkPermissionOnly(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag === authorizedRolesTags;
        } else {
            return authorizedRolesTags.find(role => role === userRoleTag) !== undefined;
        }
    }

    checkPermissionExcept(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag !== authorizedRolesTags;
        } else {
            if (authorizedRolesTags.find(role => role === userRoleTag) !== undefined) {
                return false;
            }
            return true;
        }
    }
}
