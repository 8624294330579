import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ErrorModalModel } from '@app/shared/models/error-modal.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private modalId = 'errorModal';

    private openModalErrorSource = new Subject<ErrorModalModel>();
    public openModalError$ = this.openModalErrorSource.asObservable();

    constructor() {
    }

    openModalError(error: any, message = ''): void {
        this.openModalErrorSource.next({modalId: this.modalId, error: error.error.error || error.error, message: message});
    }

}
