export class Constants {
    public static readonly TOKEN_NAME = 'AZWORLD_TOKEN_D';
    public static readonly REFRESH_TOKEN_NAME = 'AZWORLD_REFRESH_TOKEN_D';
    public static readonly USER = 'AZWORLD_USER_D';
    public static readonly LOGIN_TYPE = 'AZWORLD_LOGIN_TYPE';

    public static readonly ENVIRONMENT_LOCAL = 'local';
    public static readonly ENVIRONMENT_DEV = 'dev';
    public static readonly ENVIRONMENT_QA = 'qa';
    public static readonly ENVIRONMENT_STAGING = 'staging';
    public static readonly ENVIRONMENT_CRP_DEV = 'crp-dev';
    public static readonly ENVIRONMENT_CRP_TEST = 'crp-test';
    public static readonly ENVIRONMENT_CRP_PREPROD = 'crp-preprod';
    public static readonly ENVIRONMENT_PROD = 'prod';
    public static readonly ENVIRONMENT_PROD_GROUP = ['crp-preprod', 'prod'];

    public static readonly USER_ROLES = 'USER_ROLES';
    public static readonly USER_ROLES_ADMIN = 'ADMIN';
    public static readonly USER_ROLES_AZW_EVENT_GROUP = 'AZW_EVENT_GROUP';
    public static readonly USER_ROLES_AZ_AGENT = 'AZ_AGENT';
    public static readonly USER_ROLES_AZB_FINANCIAL_CONSULTANT = 'AZB_FINANCIAL_CONSULTANT';
    public static readonly USER_ROLES_AZW_EMPLOYEE = 'AZW_EMPLOYEE';

    public static readonly USER_ROLES_ADMIN_GROUP = ['ADMIN', 'AZW_EVENT_GROUP'];

    public static readonly USER_ROLES_AGENT_GROUP = ['AZ_AGENT', 'AZB_FINANCIAL_CONSULTANT'];

    public static readonly USER_ROLES_AZ_GROUP = ['AZ_AGENT'];

    public static readonly USER_ROLES_AZB_GROUP = ['AZB_FINANCIAL_CONSULTANT'];

    public static readonly EVENT_TARGETS = 'EVENT_TARGETS';
    public static readonly EVENT_TARGETS_NETWORK = 'NETWORK';
    public static readonly EVENT_TARGETS_PUBLIC = 'PUBLIC';
    public static readonly EVENT_TARGETS_AZW_EMPLOYEE = 'AZW_EMPLOYEE';
    public static readonly EVENT_TARGETS_TRAVEL = 'TRAVEL';

    public static readonly EVENT_TARGETS_NETWORK_GROUP = ['NETWORK', 'TRAVEL'];

    public static readonly EVENT_TYPE_ICONS = 'EVENT_TYPE_ICONS';
    public static readonly EVENT_TYPE_ICONS_VALLEY_BALL_ICON = 'VALLEY_BALL_ICON';
    public static readonly EVENT_TYPE_ICONS_BASKET_BALL_ICON = 'BASKET_BALL_ICON';
    public static readonly EVENT_TYPE_ICONS_GEAS = 'GEAS';

    public static readonly COMMERCIAL_STRUCTURES = 'COMMERCIAL_STRUCTURES';
    public static readonly COMMERCIAL_STRUCTURES_AZ = 'AZ';
    public static readonly COMMERCIAL_STRUCTURES_AZB = 'AZB';
    public static readonly COMMERCIAL_STRUCTURES_NONE = 'NONE';

    public static readonly EVENT_STATUSES = 'EVENT_STATUSES';
    public static readonly EVENT_STATUSES_DRAFT = 'DRAFT';
    public static readonly EVENT_STATUSES_SCHEDULED = 'SCHEDULED';
    public static readonly EVENT_STATUSES_LIVE = 'LIVE';
    public static readonly EVENT_STATUSES_LIVE_FULL_CAPACITY = 'LIVE_FULL_CAPACITY';
    public static readonly EVENT_STATUSES_ONGOING = 'ONGOING';
    public static readonly EVENT_STATUSES_COMPLETED = 'COMPLETED';
    public static readonly EVENT_STATUSES_LIVE_SUBSCRIPTIONS_CLOSED = 'LIVE_SUBSCRIPTIONS_CLOSED';

    public static readonly EVENT_CATEGORIES = 'EVENT_CATEGORIES';
    public static readonly EVENT_CATEGORIES_PHYSICAL = 'PHYSICAL';
    public static readonly EVENT_CATEGORIES_DIGITAL = 'DIGITAL';
    public static readonly EVENT_CATEGORIES_HYBRID = 'HYBRID';

    public static readonly EVENT_TYPES = 'EVENT_TYPES';
    public static readonly EVENT_TYPES_GOLF = 'GOLF';
    public static readonly EVENT_TYPES_THEATER = 'THEATER';
    public static readonly EVENT_TYPES_MUSEUM = 'MUSEUM';
    public static readonly EVENT_TYPES_STADIUM = 'STADIUM';
    public static readonly EVENT_TYPES_TALK = 'TALK';
    public static readonly EVENT_TYPES_FINANCIAL_MARKET_DATA_ROOM = 'FINANCIAL_MARKET_DATA_ROOM';
    public static readonly EVENT_TYPES_GRAND_TOUR = 'GRAND_TOUR';
    public static readonly EVENT_TYPES_MISCELLANEOUS = 'MISCELLANEOUS';
    public static readonly EVENT_TYPES_FINANCIAL_CLUB = 'FINANCIAL_CLUB';
    public static readonly EVENT_TYPES_PRIVATE_BREAKFAST = 'PRIVATE_BREAKFAST';
    public static readonly EVENT_TYPES_BUSINESS_FORUM = 'BUSINESS_FORUM';
    public static readonly EVENT_TYPES_CLOUD = 'CLOUD';
    public static readonly EVENT_TYPES_FORMULA_E = 'FORMULA_E';
    public static readonly EVENT_TYPES_MOTO_E = 'MOTO_E';
    public static readonly EVENT_TYPES_TENNIS_INTERNATIONALS = 'TENNIS_INTERNATIONALS';
    public static readonly EVENT_TYPES_PALIO_DI_SIENA = 'PALIO_DI_SIENA';
    public static readonly EVENT_TYPES_THEATER_DIGITAL = 'THEATER_DIGITAL';
    public static readonly EVENT_TYPES_GOLF_DIGITAL = 'GOLF_DIGITAL';
    public static readonly EVENT_TYPES_MUSEUM_DIGITAL = 'MUSEUM_DIGITAL';
    public static readonly EVENT_TYPES_TALK_DIGITAL = 'TALK_DIGITAL';
    public static readonly EVENT_TYPES_FINANCIAL_MARKET_DATA_ROOM_DIGITAL = 'FINANCIAL_MARKET_DATA_ROOM_DIGITAL';
    public static readonly EVENT_TYPES_GRAND_TOUR_DIGITAL = 'GRAND_TOUR_DIGITAL';
    public static readonly EVENT_TYPES_MISCELLANEOUS_DIGITAL = 'MISCELLANEOUS_DIGITAL';
    public static readonly EVENT_TYPES_FINANCIAL_CLUB_DIGITAL = 'FINANCIAL_CLUB_DIGITAL';
    public static readonly EVENT_TYPES_PRIVATE_BREAKFAST_DIGITAL = 'PRIVATE_BREAKFAST_DIGITAL';
    public static readonly EVENT_TYPES_BUSINESS_FORUM_DIGITAL = 'BUSINESS_FORUM_DIGITAL';
    public static readonly EVENT_TYPES_LECTIO_MAGISTRALIS = 'LECTIO_MAGISTRALIS';
    public static readonly EVENT_TYPES_LECTIO_MAGISTRALIS_DIGITAL = 'LECTIO_MAGISTRALIS_DIGITAL';
    public static readonly EVENT_TYPES_INSIGHT_CONT_ART = 'INSIGHT_CONT_ART';
    public static readonly EVENT_TYPES_INSIGHT_CONT_ART_DIGITAL = 'INSIGHT_CONT_ART_DIGITAL';
    public static readonly EVENT_TYPES_STYLE_COACH = 'STYLE_COACH';
    public static readonly EVENT_TYPES_STYLE_COACH_DIGITAL = 'STYLE_COACH_DIGITAL';
    public static readonly EVENT_TYPES_TOMORROWLAND = 'TOMORROWLAND';
    public static readonly EVENT_TYPES_TOMORROWLAND_DIGITAL = 'TOMORROWLAND_DIGITAL';
    public static readonly EVENT_TYPES_WELLNESS = 'WELLNESS';
    public static readonly EVENT_TYPES_WELLNESS_DIGITAL = 'WELLNESS_DIGITAL';
    public static readonly EVENT_TYPES_STAR_COOKING = 'STAR_COOKING';
    public static readonly EVENT_TYPES_YCCS = 'YCCS';
    public static readonly EVENT_TYPES_SAN_FELICE = 'SAN_FELICE';
    public static readonly EVENT_TYPES_BUSINESS_ATELIER = 'BUSINESS_ATELIER';
    public static readonly EVENT_TYPES_PRIVATE_CYCLING = 'PRIVATE_CYCLING';
    public static readonly EVENT_TYPES_PRIVATE_CYCLING_DIGITAL = 'PRIVATE_CYCLING_DIGITAL';
    public static readonly EVENT_TYPES_ALLIANZ_ADVISORS_ACADEMY_DIGITAL = 'ALLIANZ_ADVISORS_ACADEMY_DIGITAL';
    public static readonly EVENT_TYPES_OTHER_EVENTS_DIGITAL = 'OTHER_EVENTS_DIGITAL';
    public static readonly EVENT_TYPES_ALLIANZ_HOUSE_DIGITAL = 'ALLIANZ_HOUSE_DIGITAL';
    public static readonly EVENT_TYPES_XMAS_DINNERS_DIGITAL = 'XMAS_DINNERS_DIGITAL';
    public static readonly EVENT_TYPES_CONSULENTIA_DIGITAL = 'CONSULENTIA_DIGITAL';
    public static readonly EVENT_TYPES_CONVENTION_DIGITAL = 'CONVENTION_DIGITAL';
    public static readonly EVENT_TYPES_DNA_EVENTS_DIGITAL = 'DNA_EVENTS_DIGITAL';
    public static readonly EVENT_TYPES_DVN_EVENTS_DIGITAL = 'DVN_EVENTS_DIGITAL';
    public static readonly EVENT_TYPES_DVS_EVENTS_DIGITAL = 'DVS_EVENTS_DIGITAL';
    public static readonly EVENT_TYPES_MID_CO_EVENTS_DIGITAL = 'MID_CO_EVENTS_DIGITAL';
    public static readonly EVENT_TYPES_SAINT_FELIX_KNIGHTS_DIGITAL = 'SAINT_FELIX_KNIGHTS_DIGITAL';
    public static readonly EVENT_TYPES_LONG_TERM_INNOVATORS_DIGITAL = 'LONG_TERM_INNOVATORS_DIGITAL';
    public static readonly EVENT_TYPES_MANAGERS_MEETING_DIGITAL = 'MANAGERS_MEETING_DIGITAL';
    public static readonly EVENT_TYPES_MARKET_OUTLOOK_DAYS_DIGITAL = 'MARKET_OUTLOOK_DAYS_DIGITAL';
    public static readonly EVENT_TYPES_AGI_MEETING_DIGITAL = 'AGI_MEETING_DIGITAL';
    public static readonly EVENT_TYPES_REGIONAL_MEETING_DIGITAL = 'REGIONAL_MEETING_DIGITAL';
    public static readonly EVENT_TYPES_PIMCO_MEETING_DIGITAL = 'PIMCO_MEETING_DIGITAL';
    public static readonly EVENT_TYPES_OFFSITE_DIGITAL = 'OFFSITE_DIGITAL';
    public static readonly EVENT_TYPES_MOMENT_OF_TRUTH_SQUARE_DIGITAL = 'MOMENT_OF_TRUTH_SQUARE_DIGITAL';
    public static readonly EVENT_TYPES_PLENARY_DIGITAL = 'PLENARY_DIGITAL';
    public static readonly EVENT_TYPES_REGIONAL_PLENARY_DIGITAL = 'REGIONAL_PLENARY_DIGITAL';
    public static readonly EVENT_TYPES_AWARD_CERIMONY_DIGITAL = 'AWARD_CERIMONY_DIGITAL';
    public static readonly EVENT_TYPES_AGENTS_REPRESENTATIVE_DIGITAL = 'AGENTS_REPRESENTATIVE_DIGITAL';
    public static readonly EVENT_TYPES_SAVINGS_SALON_DIGITAL = 'SAVINGS_SALON_DIGITAL';
    public static readonly EVENT_TYPES_TECH_DAYS_DIGITAL = 'TECH_DAYS_DIGITAL';
    public static readonly EVENT_TYPES_TOUR_DIGITAL = 'TOUR_DIGITAL';
    public static readonly EVENT_TYPES_WEALTH_PROTECTION_DAYS_DIGITAL = 'WEALTH_PROTECTION_DAYS_DIGITAL';
    public static readonly EVENT_TYPES_WORKSHOP_DIGITAL = 'WORKSHOP_DIGITAL';
    public static readonly EVENT_TYPES_ALLIANZ_ADVISORS_ACADEMY = 'ALLIANZ_ADVISORS_ACADEMY';
    public static readonly EVENT_TYPES_OTHER_EVENTS = 'OTHER_EVENTS';
    public static readonly EVENT_TYPES_ALLIANZ_HOUSE = 'ALLIANZ_HOUSE';
    public static readonly EVENT_TYPES_XMAS_DINNERS = 'XMAS_DINNERS';
    public static readonly EVENT_TYPES_CONSULENTIA = 'CONSULENTIA';
    public static readonly EVENT_TYPES_CONVENTION = 'CONVENTION';
    public static readonly EVENT_TYPES_DNA_EVENTS = 'DNA_EVENTS';
    public static readonly EVENT_TYPES_DVN_EVENTS = 'DVN_EVENTS';
    public static readonly EVENT_TYPES_DVS_EVENTS = 'DVS_EVENTS';
    public static readonly EVENT_TYPES_MID_CO_EVENTS = 'MID_CO_EVENTS';
    public static readonly EVENT_TYPES_SAINT_FELIX_KNIGHTS = 'SAINT_FELIX_KNIGHTS';
    public static readonly EVENT_TYPES_LONG_TERM_INNOVATORS = 'LONG_TERM_INNOVATORS';
    public static readonly EVENT_TYPES_MANAGERS_MEETING = 'MANAGERS_MEETING';
    public static readonly EVENT_TYPES_MARKET_OUTLOOK_DAYS = 'MARKET_OUTLOOK_DAYS';
    public static readonly EVENT_TYPES_AGI_MEETING = 'AGI_MEETING';
    public static readonly EVENT_TYPES_REGIONAL_MEETING = 'REGIONAL_MEETING';
    public static readonly EVENT_TYPES_PIMCO_MEETING = 'PIMCO_MEETING';
    public static readonly EVENT_TYPES_OFFSITE = 'OFFSITE';
    public static readonly EVENT_TYPES_MOMENT_OF_TRUTH_SQUARE = 'MOMENT_OF_TRUTH_SQUARE';
    public static readonly EVENT_TYPES_PLENARY = 'PLENARY';
    public static readonly EVENT_TYPES_REGIONAL_PLENARY = 'REGIONAL_PLENARY';
    public static readonly EVENT_TYPES_AWARD_CERIMONY = 'AWARD_CERIMONY';
    public static readonly EVENT_TYPES_AGENTS_REPRESENTATIVE = 'AGENTS_REPRESENTATIVE';
    public static readonly EVENT_TYPES_SAVINGS_SALON = 'SAVINGS_SALON';
    public static readonly EVENT_TYPES_TECH_DAYS = 'TECH_DAYS';
    public static readonly EVENT_TYPES_TOUR = 'TOUR';
    public static readonly EVENT_TYPES_WEALTH_PROTECTION_DAYS = 'WEALTH_PROTECTION_DAYS';
    public static readonly EVENT_TYPES_WORKSHOP = 'WORKSHOP';
    public static readonly EVENT_TYPES_BLUE_EXPERIENCE = 'BLUE_EXPERIENCE';

    public static readonly EVENT_TYPES_AVANTGARDE = 'AVANTGARDE';
    public static readonly EVENT_TYPES_CLAIMS = 'CLAIMS';
    public static readonly EVENT_TYPES_CONTEST = 'CONTEST';
    public static readonly EVENT_TYPES_ICON = 'ICON';
    public static readonly EVENT_TYPES_ENCHANTMENT = 'ENCHANTMENT';
    public static readonly EVENT_TYPES_K_FORUM = 'K_FORUM';
    public static readonly EVENT_TYPES_MAGIC = 'MAGIC';
    public static readonly EVENT_TYPES_DREAM = 'DREAM';
    public static readonly EVENT_TYPES_TRIBE = 'TRIBE';

    public static readonly EVENT_TYPES_POWERVOLLEY_MILAN_REGULAR_SEASON = 'POWERVOLLEY_MILAN_REGULAR_SEASON';
    public static readonly EVENT_TYPES_BASKET_TRIESTE_REGULAR_SEASON = 'BASKET_TRIESTE_REGULAR_SEASON';
    public static readonly EVENT_TYPES_BASKET_GEAS_MILAN_REGULAR_SEASON = 'BASKET_GEAS_MILAN_REGULAR_SEASON';
    public static readonly EVENT_TYPES_POWERVOLLEY_MILAN_PLAYOFF = 'POWERVOLLEY_MILAN_PLAYOFF';
    public static readonly EVENT_TYPES_BASKET_TRIESTE_PLAYOFF = 'BASKET_TRIESTE_PLAYOFF';
    public static readonly EVENT_TYPES_BASKET_GEAS_MILAN_PLAYOFF = 'BASKET_GEAS_MILAN_PLAYOFF';
    public static readonly EVENT_TYPES_POWERVOLLEY_MILAN_REGULAR_SEASON_SHORT = 'POWERVOLLEY_MILAN_REGULAR_SEASON_SHORT';
    public static readonly EVENT_TYPES_BASKET_TRIESTE_REGULAR_SEASON_SHORT = 'BASKET_TRIESTE_REGULAR_SEASON_SHORT';
    public static readonly EVENT_TYPES_BASKET_GEAS_MILAN_REGULAR_SEASON_SHORT = 'BASKET_GEAS_MILAN_REGULAR_SEASON_SHORT';
    public static readonly EVENT_TYPES_POWERVOLLEY_MILAN_PLAYOF_SHORTF = 'POWERVOLLEY_MILAN_PLAYOFF_SHORT';
    public static readonly EVENT_TYPES_BASKET_TRIESTE_PLAYOFF_SHORT = 'BASKET_TRIESTE_PLAYOFF_SHORT';
    public static readonly EVENT_TYPES_BASKET_GEAS_MILAN_PLAYOFF_SHORT = 'BASKET_GEAS_MILAN_PLAYOFF_SHORT';
    public static readonly EVENT_TYPES_OTHER_EVENTS_FOR_AZW_EMPLOYEES = 'OTHER_EVENTS_FOR_AZW_EMPLOYEES';
    public static readonly EVENT_TYPES_OLIMPIA_MILAN_REGULAR_SEASON: 'OLIMPIA_MILAN_REGULAR_SEASON';
    public static readonly EVENT_TYPES_OLIMPIA_MILAN_PLAYOFF: 'OLIMPIA_MILAN_PLAYOFF';
    public static readonly EVENT_TYPES_ALLIANZ_VV_MILAN_REGULAR_SEASON: 'ALLIANZ_VV_MILAN_REGULAR_SEASON';
    public static readonly EVENT_TYPES_ALLIANZ_VV_MILAN_PLAYOFF: 'ALLIANZ_VV_MILAN_PLAYOFF';

    public static readonly EVENT_SUBSCRIPTION_STATUSES = 'EVENT_SUBSCRIPTION_STATUSES';
    public static readonly EVENT_SUBSCRIPTION_STATUSES_DRAFT = 'DRAFT';
    public static readonly EVENT_SUBSCRIPTION_STATUSES_PENDING = 'PENDING';
    public static readonly EVENT_SUBSCRIPTION_STATUSES_ACCEPTED = 'ACCEPTED';
    public static readonly EVENT_SUBSCRIPTION_STATUSES_REJECTED = 'REJECTED';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES = 'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_PENDING = 'PENDING';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_ACCEPTED = 'ACCEPTED';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_REJECTED = 'REJECTED';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_DRAFT = 'DRAFT';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_INVITED = 'INVITED';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_STATUSES_INVITATION_REFUSED = 'INVITATION_REFUSED';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES = 'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZ_AGENT = 'AZ_AGENT';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZW_EMPLOYEE = 'AZW_EMPLOYEE';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_AZB_FINANCIAL_CONSULTANT = 'AZB_FINANCIAL_CONSULTANT';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_CUSTOMER = 'CUSTOMER';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_PROSPECT = 'PROSPECT';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_PROSPECT_ON_SITE = 'PROSPECT_ON_SITE';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_TYPES_HEAD_OFFICE_GUEST = 'HEAD_OFFICE_GUEST';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES =
        'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_WINNING_DECLARATION = 'WINNING_DECLARATION';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION =
        'TOTAL_RENOUNCE_DECLARATION';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION =
        'NON_PARTICIPATION_DECLARATION';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION =
        'PARTIAL_RENOUNCE_DECLARATION';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION =
        'PARTIAL_NON_PARTICIPATION_DECLARATION';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_PRIVACY_TYPES =
        'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_PRIVACY_TYPES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_PRIVACY_TYPES_INTERNAL_PRIVACY_INFO = 'INTERNAL_PRIVACY_INFO';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_PRIVACY_TYPES_THIRD_PARTY_PRIVACY_POLICY =
        'THIRD_PARTY_PRIVACY_POLICY';

    public static readonly EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES = 'EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES';
    public static readonly EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_NOT_PARTICIPATING = 'NOT_PARTICIPATING';
    public static readonly EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_ALONE = 'ALONE';
    public static readonly EVENT_SUBSCRIPTION_SUBSCRIBER_COMPANIONSHIP_STATUSES_WITH_COMPANION_OR_COLLABORATOR =
        'WITH_COMPANION_OR_COLLABORATOR';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_CHECKIN_STATUSES =
        'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_CHECKIN_STATUSES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_CHECKIN_STATUSES_PENDING = 'PENDING';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_CHECKIN_STATUSES_PRESENT = 'PRESENT';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_CHECKIN_STATUSES_ABSENT = 'ABSENT';

    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES =
        'EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES_PENDING = 'PENDING';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES_ACCEPTED = 'ACCEPTED';
    public static readonly EVENT_EXPERIENCE_SUBSCRIPTION_PARTICIPANT_INVITATION_STATUSES_REFUSED = 'REFUSED';

    public static readonly EVENT_ATTACHMENT_TYPES = 'EVENT_ATTACHMENT_TYPES';
    public static readonly EVENT_ATTACHMENT_TYPES_COVER_IMAGE = 'COVER_IMAGE';
    public static readonly EVENT_ATTACHMENT_TYPES_HEADED_PAPER_COMMUNICATION = 'HEADED_PAPER_COMMUNICATION';
    public static readonly EVENT_ATTACHMENT_TYPES_OPERATING_INSTRUCTIONS = 'OPERATING_INSTRUCTIONS';
    public static readonly EVENT_ATTACHMENT_TYPES_INVITATION = 'INVITATION';
    public static readonly EVENT_ATTACHMENT_TYPES_PROGRAM = 'PROGRAM';
    public static readonly EVENT_ATTACHMENT_TYPES_MISCELLANEOUS = 'MISCELLANEOUS';
    public static readonly EVENT_ATTACHMENT_TYPES_DISCLAIMER = 'DISCLAIMER';
    public static readonly EVENT_ATTACHMENT_TYPES_PRESENTATION = 'PRESENTATION';
    public static readonly EVENT_ATTACHMENT_TYPES_FAQ = 'FAQ';
    public static readonly EVENT_ATTACHMENT_TYPES_WINNING_DECLARATION = 'WINNING_DECLARATION';
    public static readonly EVENT_ATTACHMENT_TYPES_TOTAL_RENOUNCE_DECLARATION = 'TOTAL_RENOUNCE_DECLARATION';
    public static readonly EVENT_ATTACHMENT_TYPES_NON_PARTICIPATION_DECLARATION = 'NON_PARTICIPATION_DECLARATION';
    public static readonly EVENT_ATTACHMENT_TYPES_PARTIAL_RENOUNCE_DECLARATION = 'PARTIAL_RENOUNCE_DECLARATION';
    public static readonly EVENT_ATTACHMENT_TYPES_PARTIAL_NON_PARTICIPATION_DECLARATION = 'PARTIAL_NON_PARTICIPATION_DECLARATION';

    public static readonly QUESTION_SCOPES = 'QUESTION_SCOPES';
    public static readonly QUESTION_SCOPES_ONLY_GUESTS = 'ONLY_GUESTS';
    public static readonly QUESTION_SCOPES_ONLY_REFERRERS = 'ONLY_REFERRERS';
    public static readonly QUESTION_SCOPES_GUESTS_AND_REFERRERS = 'GUESTS_AND_REFERRERS';

    public static readonly QUESTION_TYPES = 'QUESTION_TYPES';
    public static readonly QUESTION_TYPES_OPEN_TEXT = 'OPEN_TEXT';
    public static readonly QUESTION_TYPES_DROPDOWN = 'DROPDOWN';
    public static readonly QUESTION_TYPES_CHECK_BOX = 'CHECK_BOX';
    public static readonly QUESTION_TYPES_DATE = 'DATE';
    public static readonly QUESTION_TYPES_NUMERICAL = 'NUMERICAL';
    public static readonly QUESTION_TYPES_FILE = 'FILE';
    public static readonly QUESTION_TYPES_RADIO_BUTTON = 'RADIO_BUTTON';
    public static readonly QUESTION_TYPES_BREAK_POINT = 'BREAK_POINT';
    public static readonly QUESTION_TYPES_MULTIPLE_CHOICES_GROUP = [
        Constants.QUESTION_TYPES_DROPDOWN,
        Constants.QUESTION_TYPES_CHECK_BOX,
        Constants.QUESTION_TYPES_RADIO_BUTTON,
    ];

    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES = 'EVENT_EXPERIENCE_ATTACHMENT_TYPES';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_MISCELLANEOUS = 'MISCELLANEOUS';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_DISCLAIMER_1 = 'DISCLAIMER_1';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_DISCLAIMER_2 = 'DISCLAIMER_2';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_PROGRAM = 'PROGRAM';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_OPERATING_INSTRUCTIONS = 'OPERATING_INSTRUCTIONS';
    public static readonly EVENT_EXPERIENCE_ATTACHMENT_TYPES_FAQ = 'FAQ';

    public static readonly EVENT_LIST_SCOPES = 'LIST_EVENTS_SCOPES';
    public static readonly EVENT_LIST_SCOPE_UPCOMING_LIST = 'UPCOMING_LIST';
    public static readonly EVENT_LIST_SCOPE_GENERAL_LIST = 'GENERAL_LIST';
    public static readonly EVENT_LIST_SCOPE_REFERRER_LIST = 'REFERRER_LIST';
    public static readonly EVENT_LIST_SCOPE_CALENDAR = 'CALENDAR';

    public static readonly ERROR = 'ERROR';
    public static readonly ERROR_GENERIC_UNAUTH = 'GENERIC_UNAUTH';
    public static readonly ERROR_DIGITAL_USER_CANT_REGISTER = 'DIGITAL_USER_CANT_REGISTER';
    public static readonly ERROR_DIGITAL_USER_EMAIL_TAKEN = 'DIGITAL_USER_EMAIL_TAKEN';
    public static readonly ERROR_DIGITAL_USER_EMAIL_NOT_INVITED = 'DIGITAL_USER_EMAIL_NOT_INVITED';
    public static readonly ERROR_USER_EMAIL_NOT_UNIQUE = 'USER_EMAIL_NOT_UNIQUE';
    public static readonly ERROR_DIGITAL_USER_FISCAL_CODE_TAKEN = 'DIGITAL_USER_FISCAL_CODE_TAKEN';
    public static readonly ERROR_DIGITAL_USER_FISCAL_CODE_NOT_INVITED = 'DIGITAL_USER_FISCAL_CODE_NOT_INVITED';
    public static readonly ERROR_CANT_ACCEPT_PARTICIPANT_ALREADY_REGISTERED = 'CANT_ACCEPT_PARTICIPANT_ALREADY_REGISTERED';

    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP = 'EXPERIENCE_REGISTRATION_STEP';
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_TOTAL = 3;
    // public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_AGENCY = 1;
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_ADDITIONAL_INFO = 4;
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_PARTICIPATION = 1;
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_EXPERIENCES = 2;
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY = 3;
    public static readonly EXPERIENCE_REGISTRATION_MODAL_STEP_PRIVACY_INTERNAL = 5;

    public static readonly ONBOARDING_MODAL_STEP_TICKETS = 1;
    public static readonly ONBOARDING_MODAL_STEP_PRIVACY = 2;
    public static readonly ONBOARDING_MODAL_STEP_ADDITIONAL_INFO = 3;
    public static readonly ONBOARDING_MODAL_STEP_ADDITIONAL_INFO_COMPANION = 4;

    public static readonly PROFILE_MODAL_STEP = 'PROFILE_MODAL_STEP';
    public static readonly PROFILE_MODAL_STEP_TOTAL = 9;
    public static readonly PROFILE_MODAL_STEP_TYPE_LOGIN = 1;
    public static readonly PROFILE_MODAL_STEP_LOGIN = 2;
    public static readonly PROFILE_MODAL_STEP_FORGOT_PASSWORD = 3;
    public static readonly PROFILE_MODAL_STEP_IS_PARTICIPANT = 4;
    public static readonly PROFILE_MODAL_STEP_PERSONAL_DATA = 5;
    public static readonly PROFILE_MODAL_STEP_SUCCESS = 6;
    public static readonly PROFILE_MODAL_STEP_RECOVERY_SUCCESS = 7;
    public static readonly PROFILE_MODAL_STEP_PENDING = 8;
    public static readonly PROFILE_MODAL_STEP_RESET_PASSWORD_SUCCESS = 9;

    public static readonly LOGIN_MODAL_STEP = 'LOGIN_MODAL_STEP';
    public static readonly LOGIN_MODAL_STEP_TOTAL = 1;
    public static readonly LOGIN_MODAL_STEP_LOGIN = 1;

    public static readonly SHARE_EVENT_MODAL_STEP = 'SHARE_EVENT_MODAL_STEP';
    public static readonly SHARE_EVENT_MODAL_STEP_TOTAL = 1;
    public static readonly SHARE_EVENT_MODAL_INVITATIONS = 1;

    public static readonly MAX_FILE_SIZE = 5000000;

    public static readonly EVENT_RANKING_STATUSES = 'EVENT_RANKING_STATUSES';
    public static readonly EVENT_RANKING_STATUSES_OPEN = 'OPEN';
    public static readonly EVENT_RANKING_STATUSES_FREEZED = 'FREEZED';

    public static readonly USER_FEEDBACK_DIGITAL_TYPES = 'USER_FEEDBACK_DIGITAL_TYPES';
    public static readonly USER_FEEDBACK_DIGITAL_TYPES_ACCESS_ALLIANZ_WORLD = 'ACCESS_ALLIANZ_WORLD';
    public static readonly USER_FEEDBACK_DIGITAL_TYPES_EVENT_REGISTRATION = 'EVENT_REGISTRATION';
    public static readonly USER_FEEDBACK_DIGITAL_TYPES_MY_ACCOUNT = 'MY_ACCOUNT';
    public static readonly USER_FEEDBACK_DIGITAL_TYPES_OTHER = 'OTHER';

    public static readonly CUSTOMER_SATISFACTION_QUESTION_TYPES = 'CUSTOMER_SATISFACTION_QUESTION_TYPES';
    public static readonly CUSTOMER_SATISFACTION_QUESTION_TYPES_RANKED_NUMERICAL = 'RANKED_NUMERICAL';
    public static readonly CUSTOMER_SATISFACTION_QUESTION_TYPES_OPEN_TEXT = 'OPEN_TEXT';
    public static readonly CUSTOMER_SATISFACTION_QUESTION_TYPES_RANKED_STAR = 'RANKED_STAR';
}
