import { Injectable } from '@angular/core';
import { HttpBackend, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// import { NGXLoggerHttpService, NGXLogInterface } from 'ngx-logger';

import { LocalStorageManagementService } from './local-storage-management.service';

import { Constants } from '@app/core/constants/constants';
import { environment } from '@env/environment';


@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    constructor(
        private httpBackendLog: HttpBackend,
        private localStorageManagementService: LocalStorageManagementService
    ) {
        // super(httpBackendLog);
    }

    // logOnServer(url: string, log: NGXLogInterface, options: object): Observable<any> {
    //     const body = {
    //         user_id: 0,
    //         token: localStorage.getItem(Constants.TOKEN_NAME),
    //         url: log.additional[0].url,
    //         message: log.message,
    //         stack: log.additional[0].stack,
    //         local_storage: this.localStorageManagementService.getLocalStorage()
    //     };

    //     const req = new HttpRequest<any>('POST', environment.apiUrl + url, body, options || {});

    //     return this.httpBackendLog.handle(req).pipe(
    //         filter((e: any) => e instanceof HttpResponse),
    //         map<HttpResponse<any>, any>((httpResponse: HttpResponse<any>) => httpResponse.body)
    //     );
    // }
}
