import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AgencyModel } from '@app/shared/models/agency.model';
import { AttachmentModel } from '@app/shared/models/attachment.model';
import { EventModel } from '@app/shared/models/event.model';
import { ExperienceCheckInModel } from '@app/shared/models/experience.model';

@Injectable({
    providedIn: 'root',
})
export class ExperienceService {
    constructor(private http: HttpClient) {}

    //
    // ─── HTTP REQUEST - GET ────────────────────────────────────────────────────────
    //

    getAttachment(id: number): Observable<AttachmentModel> {
        const url = `event_experience_attachments/${id}`;

        return this.http.get<AttachmentModel>(url);
    }

    getAgencies(azAgentId: number): Observable<AgencyModel[]> {
        const url = 'az_agent_agencies';
        const params = {
            az_agent_id: azAgentId.toString(),
        };

        return this.http.get<AgencyModel[]>(url, { params });
    }

    getParticipantAdditionalInformationAnswers(params: any): Observable<any> {
        const url = `event_experience_subscription_participants/candidate/additional_information`;

        return this.http.get<any>(url, { params });
    }

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    sendAgentSubscription(formData: any, experienceId: number): Observable<EventModel> {
        return this.http.post<EventModel>(`event_experiences/${experienceId}/subscribe`, formData);
    }

    deleteUserSubscription(userId: number): Observable<EventModel> {
        return this.http.post<EventModel>(`event_experience_subscription_participants/${userId}/cancel_participation`, '');
    }

    //
    // ─── HTTP REQUEST - PATCH ────────────────────────────────────────────────────────
    //

    sendUserSubscription(formData: any, userId: number): Observable<EventModel> {
        return this.http.patch<EventModel>(`event_experience_subscription_participants/${userId}`, formData);
    }

    checkInSubscription(userId: number): Observable<ExperienceCheckInModel> {
        return this.http.patch<ExperienceCheckInModel>(`event_experience_subscription_participants/${userId}/check_in`, '');
    }

    editSubscription(formData: any, subscriptionId: number): Observable<EventModel> {
        return this.http.patch<EventModel>(`event_subscriptions/${subscriptionId}`, formData);
    }

    //
    // ─── HTTP REQUEST - DELETE ────────────────────────────────────────────────────────
    //

    deleteAgentSubscription(userId: number): Observable<EventModel> {
        return this.http.delete<EventModel>(`event_experience_subscription_participants/${userId}`);
    }

    //
    // ─── RXJS ─────────────────────────────────────────────────────────────────────────
    //
}
