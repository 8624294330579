import { Injectable, NgModule } from '@angular/core';

import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxActionModule } from '@aposin/ng-aquila/action';
import { NxAvatarModule } from '@aposin/ng-aquila/avatar';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NX_DATE_LOCALE, NxDatefieldModule, NxDatepickerIntl } from '@aposin/ng-aquila/datefield';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxFooterModule } from '@aposin/ng-aquila/footer';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxImageModule } from '@aposin/ng-aquila/image';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxIsoDateModule } from '@aposin/ng-aquila/iso-date-adapter';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { NxSidebarModule } from '@aposin/ng-aquila/sidebar';
import { NxSliderModule } from '@aposin/ng-aquila/slider';
import { NxTabsModule } from '@aposin/ng-aquila/tabs';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { NxRatingModule } from '@aposin/ng-aquila/rating';

@Injectable()
export class MyIntl extends NxDatepickerIntl {
    calendarLabel = 'Calendario';
    openCalendarLabel = 'Apri il calendario';
    prevMonthLabel = 'Mese precedente';
    nextMonthLabel = 'Mese successivo';
    prevYearLabel = 'Anno precedente';
    nextYearLabel = 'Anno successivo';
    prevMultiYearLabel = '20 anni precedenti';
    nextMultiYearLabel = '20 anni successivi';
    switchToMonthViewLabel = 'Scegli una data';
    switchToMultiYearViewLabel = 'Scegli anno e mese';
}

@NgModule({
    providers: [
        { provide: NX_DATE_LOCALE, useValue: 'it-IT' },
        { provide: NxDatepickerIntl, useClass: MyIntl },
    ],
    imports: [
        NxHeaderModule,
        NxLinkModule,
        NxImageModule,
        NxIconModule,
        NxButtonModule,
        NxAvatarModule,
        NxPopoverModule,
        NxFooterModule,
        NxHeadlineModule,
        NxGridModule,
        NxInputModule,
        NxFormfieldModule,
        NxDropdownModule,
        NxDatefieldModule,
        NxModalModule,
        NxIsoDateModule,
        NxSidebarModule,
        NxActionModule,
        NxBadgeModule,
        NxTabsModule,
        NxCopytextModule,
        NxSliderModule,
        NxCheckboxModule,
        NxRadioModule,
        NxTooltipModule,
        NxAccordionModule,
        NxRatingModule,
    ],
    exports: [
        NxHeaderModule,
        NxLinkModule,
        NxImageModule,
        NxIconModule,
        NxButtonModule,
        NxAvatarModule,
        NxPopoverModule,
        NxFooterModule,
        NxHeadlineModule,
        NxGridModule,
        NxInputModule,
        NxFormfieldModule,
        NxDropdownModule,
        NxDatefieldModule,
        NxModalModule,
        NxIsoDateModule,
        NxSidebarModule,
        NxActionModule,
        NxBadgeModule,
        NxTabsModule,
        NxCopytextModule,
        NxSliderModule,
        NxCheckboxModule,
        NxRadioModule,
        NxTooltipModule,
        NxAccordionModule,
        NxRatingModule,
    ],
})
export class NdbxModule {}
